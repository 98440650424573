import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';  

const ManagePackageNavbar = () => {  
    return (
        <>
            <div className="bg-white mt-3 shadow p-3">
                <div className="d-flex justify-content-start">
                    <div className="me-3">
                        <Link to="/manage-email-package" className="text-decoration-none amz">Update Email Package</Link>
                    </div>
                    <div className="me-3">
                        <Link to="/add-tax" className="text-decoration-none amz">Add Tax</Link>
                    </div>

                    <div className="me-3">
                        <Link to="/add-package" className="text-decoration-none amz">Create Package</Link>
                    </div>
                    
                    
 
                </div>
            </div>
        </>
    );
};

export default ManagePackageNavbar;  
