import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../BaseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Component/Navbar";
import AllStateList from "../Component/AllStateList";
import ManageWebsiteNavbar from "../Component/ManageWebsiteNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const AddState = () => {
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [preview, setPreview] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();

  // Fetch all countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetch-all-countries`);
        if (response.data.success) {
          setCountries(response.data.countries);
        } else {
          toast.error("Failed to fetch countries");
        }
      } catch (error) {
        toast.error("An error occurred while fetching countries");
      }
    };

    fetchCountries();
  }, []);

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("state_name", stateName);
    formData.append("country_id", country); // Changed from country to country_id
    formData.append("state_image", imageFile);

    // Log the form data for debugging
    console.log("Form Data Sent:", {
        state_name: stateName,
        country_id: country,
        state_image: imageFile ? imageFile.name : null, // Log the image file name if exists
    });

    try {
        const response = await axios.post(`${BASE_URL}/add-state`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        // Log the full response for debugging
        console.log(response.data); // Log the response data

        if (response.data.success) {
            toast.success("State added successfully!");
            // Reload the page after success
            setTimeout(() => {
                window.location.reload();
            }, 2000); // Optional delay before reloading
        } else {
            toast.error("Failed to add state");
        }
    } catch (error) {
        // Log the error response for more context
        console.error("Error response:", error.response ? error.response.data : error.message);
        toast.error("An error occurred while adding the state");
    }
};



  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <ManageWebsiteNavbar />
        <ToastContainer />
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <div className="row">
                  <div className="col">
                    <h2 className="color-red">Create State</h2>
                  </div>
                  <div className="col text-end">
                    <Link
                      className="justify-content-end amz atag d-flex align-items-center"
                      style={{ textDecoration: "none" }}
                      data-bs-toggle="modal"
                      data-bs-target="#statemodal"
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        className="amz"
                        style={{
                          cursor: "pointer",
                          color: "#fff",
                          padding: "4px 0px",
                          fontSize: "1rem",
                        }}
                      />
                      <span style={{ marginLeft: "8px" }}>Add New State</span>
                    </Link>
                  </div>
                </div>
                <div className="ptag">Create New State For Evventoz</div>

                {/* Modal Start */}
                <div
                  className="modal fade"
                  id="statemodal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Enter a new state
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label htmlFor="state_name" className="label">
                                <span className="a-size-base">State Full Name</span>
                              </label>
                              <input
    id="state_name"
    className="input-class"
    value={stateName} // Ensure this is properly updating
    onChange={(e) => setStateName(e.target.value)} // Ensure this is correctly triggering
    required
/>

                            </div>

                            <div className="col-md-12 mb-3">
        <label htmlFor="countrySelect">Select Country</label>
        <select
          id="countrySelect"
          className="form-control"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          <option value="">Select a country</option>
          {countries.map((country) => (
            <option key={country.id} value={country.id}>
              {country.country_name}
            </option>
          ))}
        </select>
      </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="image" className="label">
                                <span className="a-size-base">Image</span>
                              </label>
                              <input
                                id="image"
                                type="file"
                                className="input-class"
                                accept="image/*"
                                onChange={handleImageChange}
                                required
                              />
                              {preview && (
                                <div className="mt-2">
                                  <img
                                    src={preview}
                                    alt="Preview"
                                    style={{ width: "100%", height: "auto" }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <button type="submit" className="bttn">
                            Add State
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal End */}

                <AllStateList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddState;
