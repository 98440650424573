import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import BASE_URL from "../BaseURL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash,faAdd } from "@fortawesome/free-solid-svg-icons";

const AllPackageList = () => {
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingState, setEditingState] = useState({});

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const response = await fetch(`${BASE_URL}/packages`);
                const result = await response.json();

                if (result.message === "Packages retrieved successfully!") {
                    setPackages(result.packages);
                    const initialEditingState = result.packages.reduce((acc, pkg) => {
                        acc[pkg.id] = {
                            isEditing: false,
                            updatedPriceInr: pkg.package_price_inr,
                            updatedPriceUsd: pkg.package_price_usd,
                            updatedQuarterlyInr: pkg.quarterly_inr,
                            updatedQuarterlyUsd: pkg.quarterly_usd,
                            updatedHalfYearlyInr: pkg.half_year_inr,
                            updatedHalfYearlyUsd: pkg.half_year_usd,
                            updatedYearlyInr: pkg.year_inr,
                            updatedYearlyUsd: pkg.year_usd,
                        };
                        return acc;
                    }, {});
                    setEditingState(initialEditingState);
                } else {
                    setError("No packages found.");
                }
            } catch (err) {
                console.error("Error fetching packages:", err);
                setError("Failed to fetch packages.");
            } finally {
                setLoading(false);
            }
        };

        fetchPackages();
    }, []);

    const handleDeletePackage = async (packageId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`${BASE_URL}/delete-package/${packageId}`, {
                        method: 'DELETE',
                    });

                    const result = await response.json();
                    if (result.message === 'Package deleted successfully!') {
                        setPackages(packages.filter((pkg) => pkg.id !== packageId));
                        Swal.fire('Deleted!', 'The package has been deleted.', 'success');
                    } else {
                        Swal.fire('Error!', 'Failed to delete the package.', 'error');
                    }
                } catch (err) {
                    console.error("Error deleting package:", err);
                    Swal.fire('Error!', 'Failed to delete the package.', 'error');
                }
            }
        });
    };

    const handleUpdatePackage = async (packageId) => {
        const {
            updatedPriceInr,
            updatedPriceUsd,
            updatedQuarterlyInr,
            updatedQuarterlyUsd,
            updatedHalfYearlyInr,
            updatedHalfYearlyUsd,
            updatedYearlyInr,
            updatedYearlyUsd
        } = editingState[packageId];
    
        // Check for empty fields
        if (
            updatedPriceInr === undefined || updatedPriceInr === null ||
            updatedPriceUsd === undefined || updatedPriceUsd === null ||
            updatedQuarterlyInr === undefined || updatedQuarterlyInr === null ||
            updatedQuarterlyUsd === undefined || updatedQuarterlyUsd === null ||
            updatedHalfYearlyInr === undefined || updatedHalfYearlyInr === null ||
            updatedHalfYearlyUsd === undefined || updatedHalfYearlyUsd === null ||
            updatedYearlyInr === undefined || updatedYearlyInr === null ||
            updatedYearlyUsd === undefined || updatedYearlyUsd === null
        ) {
            Swal.fire('Error!', 'Package prices cannot be empty.', 'error');
            return;
        }
    
        try {
            const response = await fetch(`${BASE_URL}/update-package/${packageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    price_inr: updatedPriceInr,
                    price_usd: updatedPriceUsd,
                    quarterly_inr: updatedQuarterlyInr,
                    quarterly_usd: updatedQuarterlyUsd,
                    half_year_inr: updatedHalfYearlyInr,
                    half_year_usd: updatedHalfYearlyUsd,
                    year_inr: updatedYearlyInr,
                    year_usd: updatedYearlyUsd,
                }),
            });
    
            const result = await response.json();
            if (result.message === 'Package updated successfully!') {
                setPackages((prevPackages) =>
                    prevPackages.map((pkg) =>
                        pkg.id === packageId ? {
                            ...pkg,
                            package_price_inr: updatedPriceInr,
                            package_price_usd: updatedPriceUsd,
                            quarterly_inr: updatedQuarterlyInr,
                            quarterly_usd: updatedQuarterlyUsd,
                            half_year_inr: updatedHalfYearlyInr,
                            half_year_usd: updatedHalfYearlyUsd,
                            year_inr: updatedYearlyInr,
                            year_usd: updatedYearlyUsd,
                        } : pkg
                    )
                );
                Swal.fire('Success!', 'The package has been updated.', 'success');
            } else {
                Swal.fire('Error!', 'Failed to update the package.', 'error');
                console.error("Update error message:", result.message);
            }
        } catch (err) {
            console.error("Error updating package ID:", packageId, "Error details:", err);
            Swal.fire('Error!', 'Failed to update the package.', 'error');
        }
    };
    

    const handleEditToggle = (packageId) => {
        setEditingState((prev) => ({
            ...prev,
            [packageId]: {
                ...prev[packageId],
                isEditing: !prev[packageId].isEditing,
            }
        }));
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    if (error) return <div>{error}</div>;

    return (
        <>
            <hr />
            <div className='row ft-head-table py-3 mt-2 mb-2'>
                <div className='col'>Package Name</div>
                <div className='col'>Price (INR)</div>
                <div className='col'>Price (USD)</div>
                <div className='col'>Quarterly (INR)</div>
                <div className='col'>Quarterly (USD)</div>
                <div className='col'>Half Yearly (INR)</div>
                <div className='col'>Half Yearly (USD)</div>
                <div className='col'>Yearly (INR)</div>
                <div className='col'>Yearly (USD)</div> 
                <div className='col'>Update</div>
                <div className='col'>Action</div>
            </div>

            {packages.map((pkg) => {
                const { isEditing, updatedPriceInr, updatedPriceUsd, updatedQuarterlyInr, updatedQuarterlyUsd, updatedHalfYearlyInr, updatedHalfYearlyUsd, updatedYearlyInr, updatedYearlyUsd } = editingState[pkg.id] || {
                    isEditing: false,
                    updatedPriceInr: pkg.package_price_inr,
                    updatedPriceUsd: pkg.package_price_usd,
                    updatedQuarterlyInr: pkg.quarterly_price_inr,
                    updatedQuarterlyUsd: pkg.quarterly_price_usd,
                    updatedHalfYearlyInr: pkg.half_yearly_price_inr,
                    updatedHalfYearlyUsd: pkg.half_yearly_price_usd,
                    updatedYearlyInr: pkg.yearly_price_inr,
                    updatedYearlyUsd: pkg.yearly_price_usd,
                };

                return (
                    <div key={pkg.id} className='row py-2 ft-table'>
                        <div className='col align-content-center'>{pkg.package_name}</div>
                        <div className='col align-content-center'>
                            <input
                                type="number" className="mxwdinpt"
                                value={updatedPriceInr}
                                onChange={(e) => setEditingState((prev) => ({
                                    ...prev,
                                    [pkg.id]: {
                                        ...prev[pkg.id],
                                        updatedPriceInr: e.target.value
                                    }
                                }))}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className='col align-content-center'>
                            <input
                                type="number" className="mxwdinpt"
                                value={updatedPriceUsd}
                                onChange={(e) => setEditingState((prev) => ({
                                    ...prev,
                                    [pkg.id]: {
                                        ...prev[pkg.id],
                                        updatedPriceUsd: e.target.value
                                    }
                                }))}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className='col align-content-center'>
                            <input
                                type="number" className="mxwdinpt"
                                value={updatedQuarterlyInr}
                                onChange={(e) => setEditingState((prev) => ({
                                    ...prev,
                                    [pkg.id]: {
                                        ...prev[pkg.id],
                                        updatedQuarterlyInr: e.target.value
                                    }
                                }))}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className='col align-content-center'>
                            <input
                                type="number" className="mxwdinpt"
                                value={updatedQuarterlyUsd}
                                onChange={(e) => setEditingState((prev) => ({
                                    ...prev,
                                    [pkg.id]: {
                                        ...prev[pkg.id],
                                        updatedQuarterlyUsd: e.target.value
                                    }
                                }))}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className='col align-content-center'>
                            <input
                                type="number" className="mxwdinpt"
                                value={updatedHalfYearlyInr}
                                onChange={(e) => setEditingState((prev) => ({
                                    ...prev,
                                    [pkg.id]: {
                                        ...prev[pkg.id],
                                        updatedHalfYearlyInr: e.target.value
                                    }
                                }))}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className='col align-content-center'>
                            <input
                                type="number" className="mxwdinpt"
                                value={updatedHalfYearlyUsd}
                                onChange={(e) => setEditingState((prev) => ({
                                    ...prev,
                                    [pkg.id]: {
                                        ...prev[pkg.id],
                                        updatedHalfYearlyUsd: e.target.value
                                    }
                                }))}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className='col align-content-center'>
                            <input
                                type="number" className="mxwdinpt"
                                value={updatedYearlyInr}
                                onChange={(e) => setEditingState((prev) => ({
                                    ...prev,
                                    [pkg.id]: {
                                        ...prev[pkg.id],
                                        updatedYearlyInr: e.target.value
                                    }
                                }))}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className='col align-content-center'>
                            <input
                                type="number" className="mxwdinpt"
                                value={updatedYearlyUsd}
                                onChange={(e) => setEditingState((prev) => ({
                                    ...prev,
                                    [pkg.id]: {
                                        ...prev[pkg.id],
                                        updatedYearlyUsd: e.target.value
                                    }
                                }))}
                                disabled={!isEditing}
                            />
                        </div> 
                        <div className='col align-content-center'>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    if (isEditing) {
                                        handleUpdatePackage(pkg.id);
                                    }
                                    handleEditToggle(pkg.id);
                                }}
                            >
                                {isEditing ? 'Save' : 'Edit'}
                            </button>
                        </div>
                        <div className='col align-content-center'>
                            <button
                                className="btn btn-danger"
                                onClick={() => handleDeletePackage(pkg.id)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </button>

                            <Link to={`/add-package-list/${pkg.id}`} className="btn btn-info">
    <FontAwesomeIcon icon={faAdd} />
</Link>


                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default AllPackageList;
