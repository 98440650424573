import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../BaseURL";
import Image_URL from "../ImageURL";
import Navbar from "../Component/Navbar";
import ManageEventNavbar from "../Component/ManageEventNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons"; // Import the delete and view icons
import Modal from "react-bootstrap/Modal"; 
import { Link } from 'react-router-dom';


const AllEventsList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedEvent, setSelectedEvent] = useState(null); // Selected event details

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${BASE_URL}/fetchAllEvents`); // Change to the correct endpoint
        const result = await response.json();

        if (result.success) {
          setEvents(result.events);
        } else {
          setError("No events found.");
        }
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Failed to fetch events.");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();

    const timeout = setTimeout(() => {
      setImageLoaded(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const indexOfLastEvent = currentPage * perPage;
  const indexOfFirstEvent = indexOfLastEvent - perPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  const handlePageChange = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const totalPages = Math.ceil(events.length / perPage);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleDeleteEvent = async (eventId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${BASE_URL}/delete-event/${eventId}`, {
            // Change to the correct endpoint
            method: "DELETE",
          });

          const result = await response.json();
          if (result.success) {
            setEvents(events.filter((event) => event.id !== eventId));
            Swal.fire("Deleted!", "The event has been deleted.", "success");
          } else {
            Swal.fire("Error!", "Failed to delete the event.", "error");
          }
        } catch (err) {
          console.error("Error deleting event:", err);
          Swal.fire("Error!", "Failed to delete the event.", "error");
        }
      }
    });
  };

  const handleShowModal = (event) => {
    setSelectedEvent(event); // Set selected event
    setShowModal(true); // Show modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close modal
    setSelectedEvent(null); // Clear selected event
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  if (error) return <div>{error}</div>;

  return (
    <>
    <Navbar />
    <ManageEventNavbar />
    <div className="container-fluid">
      <div className="row justify-content-start">
        <div className="col-md-12">
          <div className="bg-white mt-3 shadow p-3">
            <div className="text-start">
              <h2 className="color-red">All Events</h2>
            </div>
            <hr />
            <div className="row mt-3 mb-3">
              <div className="col-md-10">
                <input
                  className="input-class"
                  placeholder="Search by Event Name.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="col-md-2 text-end">
                <select
                  className="input-class w-100"
                  value={perPage}
                  onChange={(e) => setPerPage(Number(e.target.value))}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                </select>
              </div>
            </div>

            <div className="row ft-head-table py-3 mt-2 mb-2">
              <div className="col">Image</div>
              <div className="col">Event Name</div>
              <div className="col">Event Type</div>
              <div className="col">Created Date</div>
              <div className="col">Event Status</div>
              <div className="col">Premium</div>
              <div className="col">Action</div>
            </div>

            {currentEvents.map((event) => (
              <div key={event.id} className="row py-2 ft-table">
                <div className="col align-content-center">
                  {/* Assuming each event has an associated image */}
                  {!imageLoaded ? (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ width: "30px", height: "30px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <img
                      src={`${Image_URL}${event.event_image}`} // Assuming each event has an 'event_image' property
                      alt={event.event_name}
                      style={{ width: "100px", display: "block" }}
                      loading="lazy"
                    />
                  )}
                </div>
                <div className="col align-content-center">
                  {event.event_name}
                </div>
                <div className="col align-content-center">
                  {event.event_type}
                </div>
                <div className="col align-content-center">
                  {new Date(event.regdate).toLocaleDateString()}
                </div>

                <div className="col align-content-center">
  <span
    className={`badge text-capitalize ${
      event.event_status === 'draft'
        ? 'bg-badge-1'
        : event.event_status === 'publish'
        ? 'bg-badge-2'
        : 'bg-badge-3'
    }`}
  >
    {event.event_status}
  </span>
</div>



                <div className="col align-content-center">
                <span class="badge bg-badge-4 text-capitalize">{event.event_premium_status}</span>
                  
                </div>


                <div className="col align-content-center">
                   

                  <button
                    className="btn btn-info"
                    onClick={() => handleShowModal(event)} // Show modal with event details
                    title="View Event Details" // Optional: Add a tooltip
                  >
                    <FontAwesomeIcon icon={faEye} />{" "}
                     
                  </button>

                  <Link 
  to={`/view-event-detail/${event.event_unique_id}`} 
  className="btn btn-warning"
  onClick={() => handleShowModal(event)}  // Triggers the modal function
  title="View Event Details"
>
  <FontAwesomeIcon icon={faEye} />{" "} 
  All Details
</Link>
                </div>
              </div>
            ))}

            <nav>
              <ul className="pagination">
                {pageNumbers.map((number) => (
                  <li key={number} className="page-item">
                    <button
                      id={number}
                      onClick={handlePageChange}
                      className="page-link"
                    >
                      {number}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            {/* Modal for displaying event details */}
            {selectedEvent && (
    <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header className="hdrclas" closeButton>
            <Modal.Title>{selectedEvent.event_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                {/* <h5>{selectedEvent.event_name}</h5> */}
                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Unique ID</div>
                    <div className="col-6">{selectedEvent.event_unique_id}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Type</div>
                    <div className="col-6">{selectedEvent.event_type}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Mode</div>
                    <div className="col-6">{selectedEvent.event_mode}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Industry</div>
                    <div className="col-6">{selectedEvent.event_industry}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Dates</div>
                    <div className="col-6">
                        {new Date(selectedEvent.event_start_date).toLocaleDateString("en-US", {
                            weekday: "short", // Short weekday (Mon)
                            year: "numeric", // Full year (2024)
                            month: "short", // Short month (Jan)
                            day: "2-digit", // Two-digit day (10)
                        })}{" "}
                        -{" "}
                        {new Date(selectedEvent.event_end_date).toLocaleDateString("en-US", {
                            weekday: "short",
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                        })}
                    </div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Frequency</div>
                    <div className="col-6">{selectedEvent.event_frequency}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Slug URL</div>
                    <div className="col-6">{selectedEvent.event_slug}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Organiser</div>
                    <div className="col-6">{selectedEvent.event_organiser}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Current Status</div>
                    <div className="col-6">{selectedEvent.event_status}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Premium Status</div>
                    <div className="col-6">{selectedEvent.event_premium_status}</div>
                </div>

                <div className="row ft-table-mdl py-2 mt-1 mb-1">
                    <div className="col-6">Venue</div>
                    <div className="col-6">{selectedEvent.venue}</div>
                </div>

                 
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btndanger" variant="secondary" onClick={handleCloseModal}>
                Close
            </button>
        </Modal.Footer>
    </Modal>
)}

          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AllEventsList;
