import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../BaseURL";

const OrgAllEvent = () => {
  const { orgId } = useParams(); // Extract orgId from URL parameters
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]); // State to hold event details

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/events`, {
          organiserId: orgId, // Use orgId from URL
        });
        console.log("API Response:", response.data); // Log the API response for debugging

        // Check if the response contains events
        if (Array.isArray(response.data) && response.data.length > 0) {
          setEvents(response.data); // Set events if it's a non-empty array
        } else {
          setError("No events found.");
        }
      } catch (err) {
        console.error("Error fetching events:", err); // Log error to console
        setError("No Events Available For Organiser");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [orgId]); // Dependency array includes orgId


  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) return <div><div className="text-start">
  <h2 className="color-red">All Events By Organiser</h2>
</div>
<hr />
{error}</div>;

  return (
    <div>
      <div className="text-start">
        <h2 className="color-red">All Events By Organiser</h2>
      </div>
      <hr />

      <div className="row ft-head-table py-3 mt-2 mb-2">
        <div className="col">Event Name</div>
        <div className="col">Type</div>
        <div className="col">Event Status</div>
        <div className="col">Date</div>
      </div>

 

      {events.map((event) => (

<div class="row py-2 ft-table" key={event.id}>
         
<div class="col align-content-center">{event.event_name}</div>  
<div class="col align-content-center">{event.event_type}</div>  
<div class="col align-content-center">
<span className={`badge text-capitalize ${event.event_status === 'publish' ? 'bg-badge-2' : event.event_status === 'Draft' ? 'bg-badge-3' : 'bg-badge-4'}`}>
    {event.event_status}
</span>

</div>  
<div class="col align-content-center">{formatDate(event.event_start_date)} - {formatDate(event.event_end_date)}</div>  

</div>

       
      ))}
    </div>
  );
};

export default OrgAllEvent;
