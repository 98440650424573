import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../BaseURL";
import Navbar from "../Component/Navbar";
import ManageEventNavbar from "../Component/ManageEventNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons"; // Import the delete and view icons
import Modal from "react-bootstrap/Modal"; // Import Bootstrap Modal

const AllContactForm = () => {
  const [contactForms, setContactForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);

  useEffect(() => {
    const fetchContactForms = async () => {
      try {
        const response = await fetch(`${BASE_URL}/contact-forms`);
        const result = await response.json();

        if (result.success) {
          setContactForms(result.data);
        } else {
          setError("No contact forms found.");
        }
      } catch (err) {
        console.error("Error fetching contact forms:", err);
        setError("Failed to fetch contact forms.");
      } finally {
        setLoading(false);
      }
    };

    fetchContactForms();
  }, []);

  const indexOfLastForm = currentPage * perPage;
  const indexOfFirstForm = indexOfLastForm - perPage;

  // Filter contact forms based on search input
  const filteredForms = contactForms.filter((form) => {
    const searchLower = search.toLowerCase();
    return (
      form.name.toLowerCase().includes(searchLower) ||
      form.email.toLowerCase().includes(searchLower) ||
      form.phone.toLowerCase().includes(searchLower) ||
      new Date(form.created_at).toLocaleDateString().includes(searchLower) // Filter by formatted date
    );
  });

  const currentForms = filteredForms.slice(indexOfFirstForm, indexOfLastForm);

  const handlePageChange = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const totalPages = Math.ceil(filteredForms.length / perPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handleDeleteForm = async (formId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${BASE_URL}/delete-contact-form/${formId}`, {
            method: "DELETE",
          });

          const result = await response.json();
          if (result.success) {
            setContactForms(contactForms.filter((form) => form.id !== formId));
            Swal.fire("Deleted!", "The contact form has been deleted.", "success");
          } else {
            Swal.fire("Error!", "Failed to delete the contact form.", "error");
          }
        } catch (err) {
          console.error("Error deleting contact form:", err);
          Swal.fire("Error!", "Failed to delete the contact form.", "error");
        }
      }
    });
  };

  const handleShowModal = (form) => {
    setSelectedForm(form);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedForm(null);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) return <div>{error}</div>;

  return (
    <>
      <Navbar />
      <ManageEventNavbar />
      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <h2 className="color-red">All Contact Form Data</h2>
              </div>
              <hr />
              <div className="row mt-3 mb-3">
                <div className="col-md-10">
                  <input
                    className="input-class"
                    placeholder="Search by Name, Email, Phone, or Date..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="col-md-2 text-end">
                  <select
                    className="input-class w-100"
                    value={perPage}
                    onChange={(e) => setPerPage(Number(e.target.value))}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                  </select>
                </div>
              </div>

              <div className="row ft-head-table py-3 mt-2 mb-2"> 
                <div className="col">Name</div>
                <div className="col">Email</div>
                <div className="col">Phone</div>
                <div className="col">Message</div>
                <div className="col">Submitted Date</div>
                <div className="col">Action</div>
              </div>

              {currentForms.map((form) => (
                <div key={form.id} className="row py-2 ft-table">
                  <div className="col align-content-center">{form.name}</div>
                  <div className="col align-content-center">{form.email}</div>
                  <div className="col align-content-center">{form.phone}</div>
                  <div className="col align-content-center">{form.message}</div>
                  <div className="col align-content-center">
                    {new Date(form.created_at).toLocaleDateString()}
                  </div>
                  <div className="col align-content-center">
                    <button
                      className="btn btn-info"
                      onClick={() => handleShowModal(form)}
                      title="View Form Details"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </div>
                </div>
              ))}

              <nav>
                <ul className="pagination">
                  {pageNumbers.map((number) => (
                    <li key={number} className="page-item">
                      <button
                        id={number}
                        onClick={handlePageChange}
                        className="page-link"
                      >
                        {number}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>

              {/* Modal for displaying form details */}
              {selectedForm && (
                <Modal show={showModal} onHide={handleCloseModal} size="xl">
                  <Modal.Header className="hdrclas" closeButton>
                    <Modal.Title>{selectedForm.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div className="row ft-table-mdl py-2 mt-1 mb-1">
                        <div className="col-6">Name</div>
                        <div className="col-6">{selectedForm.name}</div>
                      </div>
                      <div className="row ft-table-mdl py-2 mt-1 mb-1">
                        <div className="col-6">Email</div>
                        <div className="col-6">{selectedForm.email}</div>
                      </div>
                      <div className="row ft-table-mdl py-2 mt-1 mb-1">
                        <div className="col-6">Phone</div>
                        <div className="col-6">{selectedForm.phone}</div>
                      </div>
                      <div className="row ft-table-mdl py-2 mt-1 mb-1">
                        <div className="col-6">Message</div>
                        <div className="col-6">{selectedForm.message}</div>
                      </div>
                      <div className="row ft-table-mdl py-2 mt-1 mb-1">
                        <div className="col-6">Submitted Date</div>
                        <div className="col-6">
                          {new Date(selectedForm.created_at).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseModal}>
                      Close
                    </button>
                    <button className="btn btn-danger" onClick={() => handleDeleteForm(selectedForm.id)}>
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllContactForm;
