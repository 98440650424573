import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear local storage
        localStorage.clear();

        // Navigate to the login page
        navigate('/');
    }, [navigate]);

    return null; // Optionally return null or a loading indicator while redirecting
};

export default Logout;
