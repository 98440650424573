import React, { useEffect, useState } from 'react';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import BASE_URL from "../BaseURL";  
import Image_URL from "../ImageURL";  
import Swal from 'sweetalert2';

const AllCityList = () => {
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [search, setSearch] = useState(""); 
    const [perPage, setPerPage] = useState(10); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [imageLoaded, setImageLoaded] = useState(false); 

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/fetch-all-cities`);
                
                console.log("Response from fetch-all-cities:", response.data);
                
                if (response.data.success) {
                    setCities(response.data.cities);
                } else {
                    setError("No cities found.");
                }
            } catch (err) {
                console.error("Error fetching cities:", err);
                setError("Failed to fetch cities.");
            } finally {
                setLoading(false); 
            }
        };
    
        fetchCities(); 
    
        const timeout = setTimeout(() => {
            setImageLoaded(true);
        }, 1000);
    
        return () => clearTimeout(timeout);
    }, []);

    const handleToggleChange = async (cityId, newStatus) => {
        // Confirm action with SweetAlert
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to mark this city as ${newStatus === 'active' ? 'Active' : 'Deactive'}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });

        if (result.isConfirmed) {
            console.log("City ID:", cityId);
            const requestData = { id: cityId, status: newStatus }; 
            console.log("Sending data:", requestData);
            
            try {
                const response = await axios.post(`${BASE_URL}/update-city-status`, requestData);
                console.log("Response from server:", response.data);
                
                setCities((prevCities) => 
                    prevCities.map((city) => 
                        city.id === cityId ? { ...city, city_status: newStatus } : city 
                    )
                );
                Swal.fire(
                    'Updated!',
                    `City status has been updated to ${newStatus === 'active' ? 'Active' : 'Deactive'}.`,
                    'success'
                );
            } catch (error) {
                console.error("Failed to update status", error);
                if (error.response) {
                    console.log("Error response data:", error.response.data);
                    setError("Failed to update city status: " + (error.response.data.message || error.message));
                } else {
                    setError("Failed to update city status.");
                }
            }
        }
    };

    const filteredCities = cities.filter((city) => 
        city.city_name.toLowerCase().includes(search.toLowerCase()) || 
        city.country_name.toLowerCase().includes(search.toLowerCase()) || 
        city.state_name.toLowerCase().includes(search.toLowerCase())
    );

    const indexOfLastCity = currentPage * perPage; 
    const indexOfFirstCity = indexOfLastCity - perPage; 
    const currentCities = filteredCities.slice(indexOfFirstCity, indexOfLastCity); 

    const handlePageChange = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const totalPages = Math.ceil(filteredCities.length / perPage); 
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } 
    if (error) return <div>{error}</div>; 

    return (
        <>
            <hr />
            <div className="row mt-3 mb-3">
                <div className="col-md-10">
                    <input
                        className="input-class"
                        placeholder="Search by City Name, Country.."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="col-md-2 text-end">
                    <select
                        className="input-class w-100"
                        value={perPage}
                        onChange={(e) => setPerPage(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                    </select>
                </div>
            </div>

            <div className='row ft-head-table py-3 mt-2 mb-2'>
                <div className='col'>Image</div>
                <div className='col'>City Name</div>
                <div className='col'>Country</div> 
                <div className='col'>State</div> 
                <div className='col'>Reg Date</div>
                <div className='col'>Action</div>
            </div>

            {currentCities.map((city) => (
                <div key={city.id} className='row py-2 ft-table'>
                    <div className='col align-content-center'>
                        {!imageLoaded ? (
                            <div className="spinner-border" role="status" style={{ width: '30px', height: '30px' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <img
                                src={`${Image_URL}city/${city.city_image}`} 
                                alt={city.city_name}
                                style={{ width: '100px', display: 'block' }}
                                loading="lazy"
                            />
                        )}
                    </div>
                    <div className='col align-content-center'>{city.city_name}</div>
                    <div className='col align-content-center'>{city.country_name}</div> 
                    <div className='col align-content-center'>{city.state_name}</div> 
                    <div className='col align-content-center'>{new Date(city.created_at).toLocaleDateString()}</div>

                    <div className='col align-content-center'>
                        <div className="form-check form-switch">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id={`flexSwitchCheckDefault${city.id}`}
                                checked={city.city_status === 'active'}
                                onChange={(e) => handleToggleChange(city.id, e.target.checked ? 'active' : 'deactive')}
                            />
                            <label className="form-check-label action-label" htmlFor={`flexSwitchCheckDefault${city.id}`}>
                                {city.city_status === 'active' ? 'Active' : 'Deactive'}
                            </label>
                        </div>
                    </div>
                </div>
            ))}

            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className="page-item">
                            <button id={number} onClick={handlePageChange} className="page-link">
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
};

export default AllCityList;
