import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../BaseURL";
import Navbar from "../Component/Navbar";
import ManagePackageNavbar from "../Component/ManagePackageNavbar";
import { useParams } from "react-router-dom";

const EmailPackage = () => {
  const { orgId } = useParams(); // Get organizer ID from URL
  const [emailPackage, setEmailPackage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    email_credit_1: "",
    price_1: "",
    email_credit_2: "",
    price_2: "",
    email_credit_3: "",
    price_3: "",
  });

  useEffect(() => {
    const fetchEmailPackageDetails = async () => {
      try {
        const response = await fetch(`${BASE_URL}/email-pack`); // Fetch email package details
        const result = await response.json();

        if (result.success) {
          setEmailPackage(result.data);
          setFormData({
            email_credit_1: result.data.email_credit_1,
            price_1: result.data.price_1,
            email_credit_2: result.data.email_credit_2,
            price_2: result.data.price_2,
            email_credit_3: result.data.email_credit_3,
            price_3: result.data.price_3,
          });
        } else {
          setError("Email package not found.");
        }
      } catch (err) {
        console.error("Error fetching email package details:", err);
        setError("Failed to fetch email package details.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmailPackageDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/update-email-pack`, {
        method: "POST", // Use PUT method to update
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Send updated form data
      });
      const result = await response.json();

      if (result.success) {
        Swal.fire("Success!", "Email package updated successfully!", "success");
      } else {
        Swal.fire("Error!", result.message, "error");
      }
    } catch (err) {
      console.error("Error updating email package:", err);
      Swal.fire("Error!", "Failed to update email package.", "error");
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  if (error) return <div>{error}</div>;

  return (
    <>
      <Navbar />
      <ManagePackageNavbar />
      <div className="container-fluid">
        <div className="row justify-content-center">
        <div className="col-md-6">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <h2 className="color-red">Email Package Details</h2>
              </div>
              <hr />
              <div className="row ft-head-table py-3 mt-2 mb-2">
        <div className="col">Email Pack</div>
        <div className="col text-center">Credit</div> 
        <div className="col text-end">Price</div> 
      </div>

      <div className="row py-2 ft-table">
        <div className="col">Email Pack 1</div>
        <div className="col text-center">{formData.email_credit_1} Credit(s)</div> 
        <div className="col text-end">&#8377;{formData.price_1}</div> 
      </div>
      <div className="row py-2 ft-table">
        <div className="col">Email Pack 2</div>
        <div className="col text-center">{formData.email_credit_2} Credit(s)</div> 
        <div className="col text-end">&#8377;{formData.price_2}</div> 
      </div>
      <div className="row py-2 ft-table">
        <div className="col">Email Pack 3</div>
        <div className="col text-center">{formData.email_credit_3} Credit(s)</div> 
        <div className="col text-end">&#8377;{formData.price_3}</div> 
      </div>


            </div>
          </div>

          <div className="col-md-6">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <h2 className="color-red">Update Email Package</h2>
              </div>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email_credit_1" className="label">
                      <span className="a-size-base">Email Credit 1</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="email_credit_1"
                      value={formData.email_credit_1}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="price_1" className="label">
                      <span className="a-size-base">Price 1</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="price_1"
                      value={formData.price_1}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email_credit_2" className="label">
                      <span className="a-size-base">Email Credit 2</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="email_credit_2"
                      value={formData.email_credit_2}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="price_2" className="label">
                      <span className="a-size-base">Price 2</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="price_2"
                      value={formData.price_2}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email_credit_3" className="label">
                      <span className="a-size-base">Email Credit 3</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="email_credit_3"
                      value={formData.email_credit_3}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="price_3" className="label">
                      <span className="a-size-base">Price 3</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="price_3"
                      value={formData.price_3}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <button type="submit" className="bttn">
                  Update Email Package
                </button>
              </form>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
};

export default EmailPackage;
