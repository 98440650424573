import React, { useEffect, useState } from 'react';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import BASE_URL from "../BaseURL";  
import Image_URL from "../ImageURL";  
import Swal from 'sweetalert2'; // Import SweetAlert2

const AllCountryList = () => {
    const [countries, setCountries] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [search, setSearch] = useState(""); 
    const [perPage, setPerPage] = useState(10); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [imageLoaded, setImageLoaded] = useState(false); 

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/fetch-all-countries`); 
                if (response.data.success) {
                    setCountries(response.data.countries); 
                } else {
                    setError("No countries found."); 
                }
            } catch (err) {
                setError("Failed to fetch countries."); 
            } finally {
                setLoading(false); 
            }
        };

        fetchCountries(); 

        const timeout = setTimeout(() => {
            setImageLoaded(true);
        }, 1000);

        return () => clearTimeout(timeout); 
    }, []); 

    const handleToggleChange = async (countryId, isChecked) => {
        const newStatus = isChecked ? 'active' : 'deactive'; 

        // Show confirmation dialog
        const result = await Swal.fire({
            title: `Are you sure you want to mark this country as ${newStatus}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });

        // If confirmed, proceed with the update
        if (result.isConfirmed) {
            try {
                const response = await axios.post(`${BASE_URL}/update-country-status`, { country_id: countryId, country_status: newStatus });
                
                if (response.data.success) {
                    setCountries((prevCountries) =>
                        prevCountries.map((country) =>
                            country.id === countryId ? { ...country, country_status: newStatus } : country
                        )
                    );
                    Swal.fire('Updated!', 'The country status has been updated.', 'success'); // Show success message
                } else {
                    console.error("Update failed: ", response.data.message);
                    setError("Failed to update country status.");
                }
            } catch (error) {
                console.error("Failed to update status:", error.response ? error.response.data : error.message);
                setError("Failed to update country status.");
            }
        }
    };

    const filteredCountries = countries.filter((country) => {
        const countryName = country.country_name ? country.country_name.toLowerCase() : '';
        const countryCode = country.country_code ? country.country_code.toLowerCase() : '';
        const countryStatus = country.country_status ? country.country_status.toLowerCase() : '';

        return (
            countryName.includes(search.toLowerCase()) ||
            countryCode.includes(search.toLowerCase()) ||
            countryStatus.includes(search.toLowerCase())
        );
    });

    const indexOfLastCountry = currentPage * perPage;
    const indexOfFirstCountry = indexOfLastCountry - perPage;
    const currentCountries = filteredCountries.slice(indexOfFirstCountry, indexOfLastCountry);

    const handlePageChange = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const totalPages = Math.ceil(filteredCountries.length / perPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } 
    if (error) return <div>{error}</div>; 

    return (
        <>
            <hr/>
            <div className="row mt-3 mb-3">
                <div className="col-md-10">
                    <input
                        className="input-class "
                        placeholder="Search by Country Name, Code, or Status.."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="col-md-2 text-end">
                    <select
                        className="input-class w-100"
                        value={perPage}
                        onChange={(e) => setPerPage(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                    </select>
                </div>
            </div>

            <div className='row ft-head-table py-3 mt-2 mb-2'>
                <div className='col'>Image</div>
                <div className='col'>Country Name</div>
                <div className='col'>Country Code</div>
                <div className='col'>Status</div>
                <div className='col'>Action</div>
            </div>

            {currentCountries.map((country) => (
                <div key={country.id} className='row py-2 ft-table'>
                    <div className='col align-content-center'>
                        {!imageLoaded ? (
                            <div className="spinner-border" role="status" style={{ width: '30px', height: '30px' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <img
                                src={`${Image_URL}country/${country.country_image}`} 
                                alt={country.country_name}
                                style={{ width: '100px', display: 'block' }}
                                loading="lazy"
                            />
                        )}
                    </div>
                    <div className='col align-content-center'>{country.country_name}</div>
                    <div className='col align-content-center'>{country.country_code}</div>
                    <div className='col align-content-center'>{country.country_status}</div>

                    <div className='col align-content-center'>
                        <div className="form-check form-switch">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id={`flexSwitchCheckDefault${country.id}`} 
                                checked={country.country_status === 'active'}  
                                onChange={(e) => handleToggleChange(country.id, e.target.checked)} 
                            />
                            <label className="form-check-label" htmlFor={`flexSwitchCheckDefault${country.id}`}></label>
                        </div>
                    </div>
                </div>
            ))}

            <nav>
                <ul className="pagination">
                    {pageNumbers.map((number) => (
                        <li key={number} className={`page-item ${currentPage === number ? "active" : ""}`}>
                            <a onClick={handlePageChange} id={number} className="page-link" href="#!">{number}</a>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
};

export default AllCountryList;
