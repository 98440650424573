import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../BaseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Component/Navbar";
import AllCountryList from "../Component/AllCountryList";
import ManageWebsiteNavbar from "../Component/ManageWebsiteNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const AddCountry = () => {
  // State variables for form handling
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!countryName) {
      toast.error("Country name is required.");
      return;
    }

    const countryData = {
      country_name: countryName,
      country_code: countryCode || null, // Allow country_code to be null
      // Removed country_status as it's no longer needed
    };

    console.log("Country Data:", countryData); // Log for debugging

    try {
      const response = await axios.post(`${BASE_URL}/add-country`, countryData);
      toast.success("Country added successfully!");

      // Reset the form fields
      setCountryName("");
      setCountryCode("");

      // Close the modal programmatically
      const modalElement = document.getElementById("countryModal");
      const modal = new window.bootstrap.Modal(modalElement);
      modal.hide();

      // Reload the page
      window.location.reload(); // Reload the page after adding the country
    } catch (error) {
      console.error("Error adding country:", error);
      toast.error(
        "Failed to add country. " + (error.response?.data?.message || "")
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <ManageWebsiteNavbar />

        <ToastContainer />
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <div className="row">
                  <div className="col">
                    <h2 className="color-red">Create Country</h2>
                  </div>
                  <div className="col text-end">
                    <Link
                      className="justify-content-end amz atag d-flex align-items-center"
                      style={{ textDecoration: "none" }}
                      data-bs-toggle="modal"
                      data-bs-target="#countryModal"
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        className="amz"
                        style={{
                          cursor: "pointer",
                          color: "#fff",
                          padding: "4px 0px",
                          fontSize: "1rem",
                        }}
                      />
                      <span style={{ marginLeft: "8px" }}>Add New Country</span>
                    </Link>
                  </div>
                </div>

                <div className="ptag">Create New Country For Evventoz Platform</div>

                {/* Modal Start */}
                <div
                  className="modal fade"
                  id="countryModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Enter a new Country
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label htmlFor="countryName" className="label">
                                <span className="a-size-base">Country Full Name</span>
                              </label>
                              <input
                                className="input-class"
                                value={countryName}
                                onChange={(e) => setCountryName(e.target.value)}
                                required
                              />
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="countryCode" className="label">
                                <span className="a-size-base">Country Code</span>
                              </label>
                              <input
                                className="input-class"
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                              />
                            </div>
                          </div>
                          <button type="submit" className="bttn">
                            Add Country
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal End */}

                <AllCountryList/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCountry;
