import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../BaseURL";
import Image_URL from "../ImageURL";
import Navbar from "../Component/Navbar";
import ManageEventNavbar from "../Component/ManageEventNavbar";
import { useParams } from "react-router-dom"; // Import useParams
import { Link } from 'react-router-dom';

const ViewEventDetail = () => {
  const { eventId } = useParams(); // Get the event ID from the URL
  const [eventDetails, setEventDetails] = useState(null); // State to store event details
  const [sessions, setSessions] = useState([]); // State to store sessions
  const [sponsors, setSponsors] = useState([]); // State to store sponsors
  const [exhibitors, setExhibitors] = useState([]); // State to store exhibitors
  const [speakers, setSpeakers] = useState([]); // State to store speakers
  const [partners, setPartners] = useState([]); // State to store partners
  const [loading, setLoading] = useState(true); // State to manage loading

  // Log the eventId to the console
  console.log("Event ID from URL:", eventId);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await fetch(`${BASE_URL}/adminEventDetailByUniqueId/${eventId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch event details");
        }
        const data = await response.json();
        
        // Log the entire API response to see its structure
        console.log("API Response:", data);
        
        // Set the event details and sessions state
        setEventDetails(data.event);
        setSessions(data.sessions); // Set sessions data
        setSponsors(data.sponsors); // Set sponsors data
        setExhibitors(data.exhibitors); // Set exhibitors data
        setSpeakers(data.speakers); // Set speakers data
        setPartners(data.partners); // Set partners data
        setLoading(false);
      } catch (error) {
        console.error(error);
        Swal.fire("Error", "Could not fetch event details.", "error"); // Alert on error
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]); // Fetch when eventId changes

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  return (
    <>
      <Navbar />
      <ManageEventNavbar />
      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <h2 className="color-red">Event Details</h2>
              </div>
              <hr />
              {eventDetails ? (




 







                <div>

<h4>{eventDetails.event_name}</h4>
                  <p>{eventDetails.description}</p> 

                    <div className="row ft-head-table py-3 mt-2 mb-2" >
    <div className="col">Key</div>
    <div className="col">Value</div>
    <div className="col">Status</div>
</div>


<div className="row py-2 ft-table">
    <div className="col">Event Type:</div>
    <div className="col">{eventDetails.event_type}</div>
    <div className="col"> 
        {eventDetails.event_type ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>



<div className="row py-2 ft-table">
    <div className="col">Start Date:</div>
    <div className="col">
        {eventDetails.event_start_date 
            ? new Date(eventDetails.event_start_date).toLocaleDateString('en-US', { 
                year: 'numeric', 
                month: 'short', 
                day: 'numeric', 
                weekday: 'long' 
              }) 
            : 'N/A'
        }
    </div>
    <div className="col"> 
        {eventDetails.event_start_date ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>

<div className="row py-2 ft-table">
    <div className="col">End Date:</div>
    <div className="col">
        {eventDetails.event_end_date 
            ? new Date(eventDetails.event_end_date).toLocaleDateString('en-US', { 
                year: 'numeric', 
                month: 'short', 
                day: 'numeric', 
                weekday: 'long' 
              }) 
            : 'N/A'
        }
    </div>
    <div className="col"> 
        {eventDetails.event_end_date ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>

<div className="row py-2 ft-table">
    <div className="col">Venue</div>
    <div className="col">{eventDetails.event_mode_venue_table}</div>
    <div className="col">
    {eventDetails.event_mode_venue_table ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>


<div className="row py-2 ft-table">
    <div className="col">Venue</div>
    <div className="col">{eventDetails.venue_name}</div>
    <div className="col">
        {eventDetails.event_mode_venue_table === null ? (
            <span className="badge text-capitalize bg-badge-3">Required</span>
        ) : eventDetails.event_mode_venue_table === 'physical' ? (
            eventDetails.venue_name ? (
                <span className="badge text-capitalize bg-badge-2">Filled</span>
            ) : (
                <span className="badge text-capitalize bg-badge-3">Required</span>
            )
        ) : eventDetails.event_mode_venue_table === 'virtual' ? (
            eventDetails.joining_link ? (
                <span className="badge text-capitalize bg-badge-2">Filled</span>
            ) : (
                <span className="badge text-capitalize bg-badge-3">Required</span>
            )
        ) : eventDetails.event_mode_venue_table === 'hybrid' ? (
            (!eventDetails.venue_name && !eventDetails.joining_link) ? (
                <span className="badge text-capitalize bg-badge-3">Required</span>
            ) : (
                <span className="badge text-capitalize bg-badge-2">Filled</span>
            )
        ) : null}
    </div>
</div>




<div className="row py-2 ft-table">
    <div className="col">Event Mode:</div>
    <div className="col">{eventDetails.event_mode}</div>
    <div className="col">
    {eventDetails.event_mode ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>



<div className="row py-2 ft-table">
    <div className="col">Event Industry:</div>
    <div className="col">{eventDetails.event_industry}</div>
    <div className="col">
    {eventDetails.event_industry ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>
<div className="row py-2 ft-table">
    <div className="col">Event Frequency:</div>
    <div className="col">{eventDetails.event_frequency}</div>
    <div className="col">
    {eventDetails.event_frequency ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>
<div className="row py-2 ft-table">
    <div className="col">Organizer</div>
    <div className="col">{eventDetails.event_organiser}</div>
    <div className="col">
    {eventDetails.event_organiser ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>
<div className="row py-2 ft-table">
    <div className="col">Country</div>
    <div className="col">{eventDetails.country_name}</div>
    <div className="col">
    {eventDetails.country_name ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>
<div className="row py-2 ft-table">
    <div className="col">State</div>
    <div className="col">{eventDetails.state_name}</div>
    <div className="col">
    {eventDetails.state_name ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>
<div className="row py-2 ft-table">
    <div className="col">City</div>
    <div className="col">{eventDetails.city_name}</div>
    <div className="col">
    {eventDetails.city_name ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>


<div className="row py-2 ft-table">
    <div className="col">Timezone</div>
    <div className="col">{eventDetails.timezone}</div>
    <div className="col">
        {eventDetails.event_mode_venue_table === 'physical' ? (
            <span className="badge text-capitalize bg-badge-2">Not Required</span>
        ) : eventDetails.event_mode_venue_table === 'virtual' || eventDetails.event_mode_venue_table === 'hybrid' ? (
            eventDetails.timezone ? (
                <span className="badge text-capitalize bg-badge-2">Filled</span>
            ) : (
                <span className="badge text-capitalize bg-badge-3">Required</span>
            )
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>




<div className="row py-2 ft-table">
    <div className="col">Joining Link</div>
    <div className="col">
        {eventDetails.joining_link ? (
            <a href={eventDetails.joining_link} target="_blank" rel="noopener noreferrer">Join Event</a>
        ) : (
            <span>No Link Available</span>
        )}
    </div>

    <div className="col">
        {eventDetails.event_mode_venue_table === 'physical' ? (
            <span className="badge text-capitalize bg-badge-2">Not Required</span>
        ) : eventDetails.event_mode_venue_table === 'virtual' || eventDetails.event_mode_venue_table === 'hybrid' ? (
            eventDetails.joining_link ? (
                <span className="badge text-capitalize bg-badge-2">Filled</span>
            ) : (
                <span className="badge text-capitalize bg-badge-3">Required</span>
            )
        ) : (<span className="badge text-capitalize bg-badge-3">Missing</span>)}
    </div>
</div>



<div className="row py-2 ft-table">
    <div className="col">Event Slug</div>
    <div className="col">{eventDetails.event_slug}</div>
    <div className="col">
    {eventDetails.event_slug ? (
            <span className="badge text-capitalize bg-badge-2">Unique Slug / No changes Required</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Missing</span>
        )}
    </div>
</div>

<div className="row py-2 ft-table">
    <div className="col">Event Front Banner</div>
    <div className="col">{eventDetails.event_image}</div>
    <div className="col">
    {eventDetails.event_image ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Required</span>
        )}
    </div>
</div>

<div className="row py-2 ft-table">
    <div className="col">Event Full Banner</div>
    <div className="col">{eventDetails.event_full_banner}</div>
    <div className="col">
    {eventDetails.event_full_banner ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-3">Required</span>
        )}
    </div>
</div>

<div className="row py-2 ft-table">
    <div className="col">Event Floorplan</div>
    <div className="col">{eventDetails.event_floorplan}</div>
    <div className="col">
    {eventDetails.event_floorplan ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-4">Missing/ Not-Required</span>
        )}
    </div>
</div>

<div className="row py-2 ft-table">
    <div className="col">Event Brochure</div>
    <div className="col">{eventDetails.event_brochure}</div>
    <div className="col">
    {eventDetails.event_brochure ? (
            <span className="badge text-capitalize bg-badge-2">Filled</span>
        ) : (
            <span className="badge text-capitalize bg-badge-4">Missing/ Not-Required</span>
        )}
    </div>
</div>

         
                  <Link to={`/editEvent/${eventId}`} className="btn btn-primary">Edit Event</Link>
                </div>
              ) : (
                <p>No event details found.</p>
              )}

              <h3 className="mt-4">Sessions</h3>
              {sessions.length > 0 ? (
                <ul className="list-group">
                  {sessions.map((session) => (
                    <li key={session.agenda_id} className="list-group-item">
                      <h5>{session.session_name}</h5>
                      <p><strong>Speaker:</strong> {session.agenda_speaker}</p>
                      <p><strong>Date:</strong> {session.session_date}</p>
                      <p><strong>Start Time:</strong> {session.start_time}</p>
                      <p><strong>End Time:</strong> {session.end_time}</p>
                      <p><strong>Agenda Topic:</strong> {session.agenda_topic}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No sessions found.</p>
              )}

              <h3 className="mt-4">Sponsors</h3>
              {sponsors.length > 0 ? (
  <div className="row">
    {sponsors.map((sponsor) => (
      <div key={sponsor.id} className="col-md-2 ">
        <div className="card p-2">
        <div className="">
          <img src={`${Image_URL}sponser/${sponsor.event_sponser_image}`} alt="" className="img-fluid" />
        </div>
        <div>
          {sponsor.event_sponser_name}
        </div>
        {/* <div>
          <a href={sponsor.event_sponser_website} target="_blank" rel="noopener noreferrer">
            {sponsor.event_sponser_website}
          </a>
        </div> */}
        <div>
          {sponsor.event_sponser_title}
        </div>
        </div>
      </div>
    ))}
  </div>
) : (
  <p>No sponsors found.</p>
)}


              <h3 className="mt-4">Exhibitors</h3>
              {exhibitors.length > 0 ? (
                <ul className="list-group">
                  {exhibitors.map((exhibitor, index) => (
                    <li key={index} className="list-group-item">
                      <h5>{exhibitor.name}</h5> {/* Assuming you have a 'name' field */}
                      {/* Add more exhibitor details as needed */}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No exhibitors found.</p>
              )}

              <h3 className="mt-4">Speakers</h3>
              {speakers.length > 0 ? (
                <ul className="list-group">
                  {speakers.map((speaker, index) => (
                    <li key={index} className="list-group-item">
                      <h5>{speaker.name}</h5> {/* Assuming you have a 'name' field */}
                      {/* Add more speaker details as needed */}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No speakers found.</p>
              )}

              <h3 className="mt-4">Partners</h3>
              {partners.length > 0 ? (
                <ul className="list-group">
                  {partners.map((partner, index) => (
                    <li key={index} className="list-group-item">
                      <h5>{partner.name}</h5> {/* Assuming you have a 'name' field */}
                      {/* Add more partner details as needed */}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No partners found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEventDetail;
