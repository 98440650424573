import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap'; // Rename imported Navbar
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is available 

const CustomNavbar = () => { // Rename your component
    return (
        <BootstrapNavbar bg="dark" variant="dark" expand="lg" sticky="top">
            <Container>
                <BootstrapNavbar.Brand as={Link} to="/">
                    <img
                        src="/path/to/logo.png" // Replace with your logo path
                        alt="Logo"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                    {' '}
                    MyApp
                </BootstrapNavbar.Brand>
                <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
                <BootstrapNavbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
                        <Nav.Link as={Link} to="/add-venue">Manage Website</Nav.Link>
                        <Nav.Link as={Link} to="/manage-events">Manage Events</Nav.Link>
                        <Nav.Link as={Link} to="/contact-form-data">Manage Tickets</Nav.Link>
                        <Nav.Link as={Link} to="/manage-organiser">Manage Organiser</Nav.Link>
                        <Nav.Link as={Link} to="/manage-email-package">Manage Packages</Nav.Link>
                        <Nav.Link as={Link} to="/logout">Logout</Nav.Link>
                    </Nav>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    );
};

export default CustomNavbar; // Update the export statement
