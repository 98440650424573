import React, { useEffect, useState } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'; // Import SweetAlert2
import 'bootstrap/dist/css/bootstrap.min.css'; 
import BASE_URL from "../BaseURL";  
import Image_URL from "../ImageURL";  

const AllStateList = () => {
    const [states, setStates] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [search, setSearch] = useState(""); 
    const [perPage, setPerPage] = useState(10); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [imageLoaded, setImageLoaded] = useState(false); 

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/fetch-all-states`); 
                
                // Log the full response to the console
                console.log("Response data:", response.data);

                if (response.data.success) {
                    setStates(response.data.states); 
                } else {
                    setError("No states found."); 
                }
            } catch (err) {
                console.error("Error fetching states:", err);
                setError("Failed to fetch states."); 
            } finally {
                setLoading(false); 
            }
        };

        fetchStates(); 

        // Allow images to load after a timeout
        const timeout = setTimeout(() => {
            setImageLoaded(true);
        }, 1000);

        return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
    }, []); 

    const handleToggleChange = async (stateId, newStatus) => {
        const requestData = { state_id: stateId, status: newStatus };
        console.log("Sending data:", requestData); // Log the data being sent

        // SweetAlert confirmation dialog
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `You want to mark this state as ${newStatus === 'active' ? 'active' : 'inactive'}.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isConfirmed) {
            try {
                const response = await axios.post(`${BASE_URL}/update-state-status`, requestData);
                console.log("Response data:", response.data); // Log the response data
                
                // Update the state based on the response
                setStates((prevStates) =>
                    prevStates.map((state) =>
                        state.state_id === stateId ? { ...state, state_status: newStatus } : state
                    )
                );

                Swal.fire(
                    'Updated!',
                    `The state has been marked as ${newStatus}.`,
                    'success'
                );
            } catch (error) {
                console.error("Failed to update status", error);
    
                // Log the error response, if available
                if (error.response) {
                    console.error("Error response data:", error.response.data);
                    console.error("Error response status:", error.response.status);
                    console.error("Error response headers:", error.response.headers);
                    setError("Failed to update state status: " + error.response.data.message || error.message);
                } else {
                    setError("Failed to update state status.");
                }
            }
        } else {
            // If the action was canceled
            Swal.fire(
                'Cancelled',
                'The state status was not updated.',
                'error'
            );
        }
    };

    const filteredStates = states.filter((state) =>
        state.state_name.toLowerCase().includes(search.toLowerCase()) ||
        state.country_name.toString().includes(search.toLowerCase())
    );

    const indexOfLastState = currentPage * perPage;
    const indexOfFirstState = indexOfLastState - perPage;
    const currentStates = filteredStates.slice(indexOfFirstState, indexOfLastState);

    const handlePageChange = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const totalPages = Math.ceil(filteredStates.length / perPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } 
    if (error) return <div>{error}</div>; 

    return (
        <>
            <hr />
            <div className="row mt-3 mb-3">
                <div className="col-md-10">
                    <input
                        className="input-class"
                        placeholder="Search by State Name, Country.."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="col-md-2 text-end">
                    <select
                        className="input-class w-100"
                        value={perPage}
                        onChange={(e) => setPerPage(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                    </select>
                </div>
            </div>

            <div className='row ft-head-table py-3 mt-2 mb-2'>
                <div className='col'>Image</div>
                <div className='col'>State Name</div>
                <div className='col'>Country</div> 
                <div className='col'>Reg Date</div>
                <div className='col'>Action</div>
            </div>

            {currentStates.map((state) => (
                <div key={state.state_id} className='row py-2 ft-table'>
                    <div className='col align-content-center'>
                        {!imageLoaded ? (
                            <div className="spinner-border" role="status" style={{ width: '30px', height: '30px' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <img
                                src={`${Image_URL}state/${state.state_image}`} 
                                alt={state.state_name}
                                style={{ width: '100px', display: 'block' }}
                                loading="lazy"
                            />
                        )}
                    </div>
                    <div className='col align-content-center'>{state.state_name}</div>
                    <div className='col align-content-center'>{state.country_name}</div> 
                    <div className='col align-content-center'>{new Date(state.created_at).toLocaleDateString()}</div>

                    <div className='col align-content-center'>
                        <div className="form-check form-switch">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id={`flexSwitchCheckDefault${state.state_id}`} 
                                checked={state.state_status === 'active'} 
                                onChange={(e) => handleToggleChange(state.state_id, e.target.checked ? 'active' : 'deactive')} 
                            />
                            <label className="form-check-label action-label" htmlFor={`flexSwitchCheckDefault${state.state_id}`}>
                                {state.state_status === 'active' ? 'Active' : 'Deactive'}
                            </label>
                        </div>
                    </div>
                </div>
            ))}

            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className="page-item">
                            <button id={number} onClick={handlePageChange} className="page-link">
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
};

export default AllStateList;
