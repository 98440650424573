import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../BaseURL";
import Image_URL from "../ImageURL";
import Navbar from "../Component/Navbar";
import ManageEventNavbar from "../Component/ManageEventNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons"; // Import the delete and view icons
import Modal from "react-bootstrap/Modal"; 
import { Link } from 'react-router-dom';

const AllOrganiser = () => {
  const [organizers, setOrganizers] = useState([]); // Changed from events to organizers
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedOrganizer, setSelectedOrganizer] = useState(null); // Selected organizer details

  useEffect(() => {
    const fetchOrganizers = async () => {
      try {
        const response = await fetch(`${BASE_URL}/fetch-all-organizers`); // Change to the correct endpoint
        const result = await response.json();

        if (result.success) {
          setOrganizers(result.organizers); // Set organizers instead of events
        } else {
          setError("No organizers found.");
        }
      } catch (err) {
        console.error("Error fetching organizers:", err);
        setError("Failed to fetch organizers.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizers();

    const timeout = setTimeout(() => {
      setImageLoaded(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const indexOfLastOrganizer = currentPage * perPage;
  const indexOfFirstOrganizer = indexOfLastOrganizer - perPage;
  const currentOrganizers = organizers.slice(indexOfFirstOrganizer, indexOfLastOrganizer);

  const handlePageChange = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const totalPages = Math.ceil(organizers.length / perPage);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  if (error) return <div>{error}</div>;

  return (
    <>
      <Navbar />
      <ManageEventNavbar />
      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <h2 className="color-red">All Organizers</h2> {/* Fixed typo from 'All Organiser' */}
              </div>
              <hr />
              <div className="row mt-3 mb-3">
                <div className="col-md-10">
                  <input
                    className="input-class"
                    placeholder="Search by Organizer Name.."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="col-md-2 text-end">
                  <select
                    className="input-class w-100"
                    value={perPage}
                    onChange={(e) => setPerPage(Number(e.target.value))}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                  </select>
                </div>
              </div>

              <div className="row ft-head-table py-3 mt-2 mb-2">
                <div className="col">ID</div> {/* Added an ID column */}
                <div className="col">Name</div>
                <div className="col">Email</div>
                <div className="col">Phone</div>
                <div className="col">Registration Date</div>
                <div className="col">Account Plan</div>
                <div className="col">Action</div>
              </div>

              {currentOrganizers.map((organizer) => (
                <div key={organizer.id} className="row py-2 ft-table">
                  <div className="col align-content-center">
                    {organizer.id} {/* Display organizer ID */}
                  </div>
                  <div className="col align-content-center">
                    {organizer.name}
                  </div>
                  <div className="col align-content-center">
                    {organizer.email}
                  </div>
                  <div className="col align-content-center">
                    {organizer.phone}
                  </div>
                  <div className="col align-content-center">
                    {new Date(organizer.regdate).toLocaleDateString()}
                  </div>
                  <div className="col align-content-center">
                    <span className={`badge text-capitalize bg-badge-1`}>
                      {organizer.account_plan}
                    </span>
                  </div>
                  <div className="col align-content-center">
                    <Link 
                      to={`/view-organizer-detail/${organizer.id}`} 
                      className="btn btn-warning"
                      title="View Organizer Details"
                    >
                      <FontAwesomeIcon icon={faEye} />{" "} 
                      View Details
                    </Link>


                    <Link 
                      to={`/update-organiser/${organizer.id}`} 
                      className="btn btn-info"
                      title="View Organizer Details"
                    >
                      <FontAwesomeIcon icon={faEye} />{" "} 
                      Edit
                    </Link>

                  </div>
                </div>
              ))}

              <nav>
                <ul className="pagination">
                  {pageNumbers.map((number) => (
                    <li key={number} className="page-item">
                      <button
                        id={number}
                        onClick={handlePageChange}
                        className="page-link"
                      >
                        {number}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllOrganiser;
