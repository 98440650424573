import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../BaseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Component/Navbar";
import AllVenueList from "../Component/AllVenueList";
import ManageWebsiteNavbar from "../Component/ManageWebsiteNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const AddVenue = () => {
  // State variables for form handling
  const [venueName, setVenueName] = useState("");
  const [venueCategory, setVenueCategory] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [mapIframe, setMapIframe] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]); // Fix: Change useCities to useState
  
  // State variables for API data
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetch-all-countries`);
        if (response.data.success) {
          setCountries(response.data.countries);
        } else {
          toast.error("Failed to fetch countries");
        }
      } catch (error) {
        toast.error("An error occurred while fetching countries");
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetch-all-states`);
        if (response.data.success) {
          setStates(response.data.states);
        } else {
          toast.error("Failed to fetch states");
        }
      } catch (error) {
        toast.error("An error occurred while fetching states");
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (country) {
      const filtered = states.filter((state) => state.country_id === parseInt(country));
      setFilteredStates(filtered);
    } else {
      setFilteredStates([]);
    }
  }, [country, states]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetch-all-cities`);
        if (response.data.success) {
          setCities(response.data.cities);
        } else {
          toast.error("Failed to fetch cities");
        }
      } catch (error) {
        toast.error("An error occurred while fetching cities");
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    if (state) {
      const filtered = cities.filter((city) => city.state_id === parseInt(state)); // Fix filtering logic
      setFilteredCities(filtered);
    } else {
      setFilteredCities([]);
    }
  }, [state, cities]);

  // Handle file upload and preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        toast.error("Image size should be less than 2MB.");
        setImage(null);
        setPreview("");
        return;
      }

      if (file.type.startsWith("image/")) {
        setImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        toast.error("Please select a valid image file.");
        setImage(null);
        setPreview("");
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!venueName || !country || !state || !city || !mapIframe || !venueCategory) {
      toast.error("All fields are required.");
      return;
    }

    const formData = new FormData();
    formData.append("venue_name", venueName);
    formData.append("venue_country", country);
    formData.append("venue_state", state);
    formData.append("venue_city", city);
    formData.append("venue_map", mapIframe);
    formData.append("venue_category", venueCategory);
    if (image) {
      formData.append("venue_image", image);
    }

    // Log the form data to console for debugging
    console.log("Form Data:", Array.from(formData.entries()));

    try {
      setLoading(true); // Set loading state to true
      const response = await axios.post(`${BASE_URL}/add-venue`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Venue created successfully!");

      // Reset the form fields
      setVenueName("");
      setCountry("");
      setState("");
      setCity("");
      setMapIframe("");
      setImage(null);
      setPreview("");

      // Close the modal programmatically
      const modalElement = document.getElementById("exampleModal");
      const modal = new window.bootstrap.Modal(modalElement);
      modal.hide();

      // Reload the page
      window.location.reload(); // Reload the page after adding the venue
    } catch (error) {
      console.error("Error adding venue:", error);
      toast.error(
        "Failed to create venue. " + (error.response?.data?.message || "")
      );
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <ManageWebsiteNavbar />
        <ToastContainer />
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <div className="row">
                  <div className="col">
                    <h2 className="color-red">Create Venue</h2>
                  </div>
                  <div className="col text-end">
                    <Link
                      className="justify-content-end amz atag d-flex align-items-center"
                      style={{ textDecoration: "none" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        className="amz"
                        style={{
                          cursor: "pointer",
                          color: "#fff",
                          padding: "4px 0px",
                          fontSize: "1rem",
                        }}
                      />
                      <span style={{ marginLeft: "8px" }}>Add New Venue</span>
                    </Link>
                  </div>
                </div>
                <div className="ptag">Create New Venue For Evventoz</div>

                {/* modal start */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog ">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Enter a new Venue
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">


                          <div className="col-md-12 mb-3">
  <label htmlFor="venueCategory" className="label">
    <span className="a-size-base">Select Venue Category</span>
  </label>
  <select
    className="input-class"
    value={venueCategory} // Make sure to define this state variable
    onChange={(e) => setVenueCategory(e.target.value)} // Set the state for the selected category
    required
    aria-required="true"
  >
    <option value="">Select Venue Category</option>
    <option value="hotels">Hotels</option>
    <option value="seminar_halls">Seminar Halls</option>
    {/* Add more static options as needed */}
  </select>
</div>



                            <div className="col-md-12 mb-3">
                              <label htmlFor="venue" className="label">
                                <span className="a-size-base">Venue Full Name</span>
                              </label>
                              <input
                                className="input-class"
                                value={venueName}
                                onChange={(e) => setVenueName(e.target.value)}
                                required
                                aria-required="true"
                              />
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="country" className="label">
                                <span className="a-size-base">Select Country</span>
                              </label>
                              <select
                                className="input-class"
                                value={country}
                                onChange={(e) => {
                                  setCountry(e.target.value);
                                  setState("");
                                  setCity("");
                                }}
                                required
                                aria-required="true"
                              >
                                <option value="">Select Country</option>
                                {countries.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.country_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="state" className="label">
                                <span className="a-size-base">Select State</span>
                              </label>
                              <select
                                className="input-class"
                                value={state}
                                onChange={(e) => {
                                  setState(e.target.value);
                                  setCity(""); // Clear city when state changes
                                }}
                                required
                                aria-required="true"
                              >
                                <option value="">Select State</option>
                                {filteredStates.map((state) => (
                                  <option key={state.state_id} value={state.state_id}>
                                    {state.state_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="city" className="label">
                                <span className="a-size-base">Select City</span>
                              </label>
                              <select
                                className="input-class"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                                aria-required="true"
                              >
                                <option value="">Select City</option>
                                {filteredCities.map((city) => (
                                  <option key={city.id} value={city.id}>
                                    {city.city_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="map" className="label">
                                <span className="a-size-base">Google Map Iframe</span>
                              </label>
                              <textarea
                                className="input-class"
                                value={mapIframe}
                                onChange={(e) => setMapIframe(e.target.value)}
                                required
                                aria-required="true"
                                rows="3"
                              />
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="image" className="label">
                                <span className="a-size-base">Upload Venue Image</span>
                              </label>
                              <input
                                type="file"
                                className="input-class"
                                onChange={handleImageChange}
                                accept="image/*"
                              />
                              {preview && (
                                <div className="mt-2">
                                  <img
                                    src={preview}
                                    alt="Preview"
                                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="modal-footer">
                            
                            <button
                              type="submit"
                              className={`bttn ${loading ? "loading" : ""}`} // Change button style based on loading
                              disabled={loading}  
                            >
                              {loading ? "Adding..." : "Add Venue"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* modal end */}
                <AllVenueList />
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default AddVenue;
