import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from '../BaseURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (username && password) {
            try {
                const response = await axios.post(`${BASE_URL}admin_login`, {
                    username: username,
                    password: password,
                });

                localStorage.setItem('adminId', response.data.admin.id);
                localStorage.setItem('adminName', response.data.admin.name);
                localStorage.setItem('adminUniqueId', response.data.admin.admin_unique_id);

                // Show success message
                toast.success('Login successful!', {
                    position: 'top-center',
                    autoClose: 2000,
                });

                // Delay navigation for 2 seconds
                setTimeout(() => {
                    navigate('/dashboard');
                }, 2000);
            } catch (error) {
                console.error('Error during login:', error.response ? error.response.data : error);
                toast.error('Login failed. Please check your credentials.', {
                    position: 'top-center',
                    autoClose: 2000,
                });
            }
        } else {
            toast.warn('Please enter both username and password.', {
                position: 'top-center',
                autoClose: 2000,
            });
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    return (
        <div className="container">
            <ToastContainer />
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card mt-5 shadow">
                        <div className="card-header text-center">
                            <h2>Login</h2>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Username</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        placeholder="Enter username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <div className="input-group">
                                        <input
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            className="form-control"
                                            id="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {isPasswordVisible ? 'Hide' : 'Show'}
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="rememberMe" />
                                    <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                </div>
                                <button type="submit" className="btn btn-primary w-100">Login</button>
                            </form>
                        </div>
                        <div className="card-footer text-center">
                            <small>Don't have an account? <a href="/register">Register here</a></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
