import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../BaseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Component/Navbar";
import AllBannerList from "../Component/AllBannerList";
import ManageWebsiteNavbar from "../Component/ManageWebsiteNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const AddBanner = () => {
  // State variables for form handling
  const [urlLink, setUrlLink] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null); // For image preview

  // Handle image file selection and preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!urlLink) {
      toast.error("URL link is required.");
      return;
    }
    if (!imageFile) {
      toast.error("Image is required.");
      return;
    }

    // Create FormData object for file upload
    const formData = new FormData();
    formData.append("banner_image", imageFile);
    formData.append("url_link", urlLink);

    try {
      const response = await axios.post(`${BASE_URL}/upload-banner`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Banner uploaded successfully!");

      // Reset the form fields
      setUrlLink("");
      setImageFile(null);
      setPreview(null);

      // Close the modal programmatically
      const modalElement = document.getElementById("bannerModal");
      const modal = new window.bootstrap.Modal(modalElement);
      modal.hide();

      // Reload the page after adding the banner
      window.location.reload();
    } catch (error) {
      console.error("Error uploading banner:", error);
      toast.error(
        "Failed to upload banner. " + (error.response?.data?.message || "")
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <ManageWebsiteNavbar />

        <ToastContainer />
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <div className="row">
                  <div className="col">
                    <h2 className="color-red">Upload Banner</h2>
                  </div>
                  <div className="col text-end">
                    <Link
                      className="justify-content-end amz atag d-flex align-items-center"
                      style={{ textDecoration: "none" }}
                      data-bs-toggle="modal"
                      data-bs-target="#bannerModal"
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        className="amz"
                        style={{
                          cursor: "pointer",
                          color: "#fff",
                          padding: "4px 0px",
                          fontSize: "1rem",
                        }}
                      />
                      <span style={{ marginLeft: "8px" }}>Add New Banner</span>
                    </Link>
                  </div>
                </div>

                <div className="ptag">
                  Create New Banner For Evventoz Platform
                </div>

                {/* Modal Start */}
                <div
                  className="modal fade"
                  id="bannerModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Upload New Banner
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label htmlFor="urlLink" className="label">
                                <span className="a-size-base">URL Link</span>
                              </label>
                              <input
                                className="input-class"
                                id="urlLink"
                                value={urlLink}
                                onChange={(e) => setUrlLink(e.target.value)}
                                required
                              />
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="image" className="label">
                                <span className="a-size-base">Banner Image</span>
                              </label>
                              <input
                                id="image"
                                type="file"
                                className="input-class"
                                accept="image/*"
                                onChange={handleImageChange}
                                required
                              />

                              {preview && (
                                <div className="mt-2">
                                  <img
                                    src={preview}
                                    alt="Preview"
                                    style={{ width: "100%", height: "auto" }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <button type="submit" className="bttn">
                            Upload Banner
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal End */}

                <AllBannerList/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBanner;
