import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../BaseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Component/Navbar";
import ManagePackageNavbar from "../Component/ManagePackageNavbar";
import Swal from "sweetalert2";

const AddPackageList = () => {
  const [packageItem, setPackageItem] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [packageName, setPackageName] = useState(""); // State for package name
  const { id } = useParams();

  // Fetch package list function
  const fetchPackageList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/package-list/${id}`);
      setPackageName(response.data.packageName); // Set package name from response
      setPackageList(response.data.packageItems); // Set package items from response
    } catch (error) {
      console.error("Error fetching package list items:", error);
      toast.error("Failed to load package list items.");
    }
  };

  useEffect(() => {
    fetchPackageList();
  }, [id]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!packageItem) {
      toast.error("Package item is required.");
      return;
    }

    const packageData = {
      item: packageItem,
      packageId: id,
    };

    try {
      await axios.post(`${BASE_URL}/add-package-list`, packageData);
      toast.success("Package item added successfully!");
      setPackageItem("");
      fetchPackageList(); // Fetch the updated list after adding
    } catch (error) {
      console.error("Error adding package item:", error);
      toast.error("Failed to add package item. " + (error.response?.data?.message || ""));
    }
  };

  // Handle delete action with SweetAlert confirmation
  const handleDelete = async (itemId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This item will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_URL}/delete-package-item/${itemId}`);
          toast.success("Package item deleted successfully!");
          fetchPackageList(); // Fetch the updated list after deletion
        } catch (error) {
          console.error("Error deleting package item:", error);
          toast.error("Failed to delete package item.");
        }
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <ManagePackageNavbar />
        <ToastContainer />
        <div className="row justify-content-start">
          <div className="col-md-6">
            <div className="bg-white mt-3 shadow p-3">
              <h2 className="color-red">Package Item</h2>
              <div className="ptag">Create New Package Item For Evventoz Platform</div>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="packageItem" className="label">
                      <span className="a-size-base">Package List Item</span>
                    </label>
                    <textarea
                      id="packageItem"
                      className="input-class"
                      value={packageItem}
                      onChange={(e) => setPackageItem(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <button type="submit" className="bttn">
                  Add Package Item
                </button>
              </form>
            </div>
          </div>

          <div className="col-md-6">
            <div className="bg-white mt-3 shadow p-3">
              <h2 className="color-red">Package Type - {packageName}</h2> {/* Display packageName */}
              <hr />
              <div className="row ft-head-table py-3 mt-2 mb-2">
                <div className="col">Package List Item</div>
                <div className="col">Actions</div>
              </div>
              {packageList.length > 0 ? (
                packageList.map((item, index) => (
                  <div key={index} className="row py-2 ft-table">
                    <div className="col">{item.package_list_item}</div>
                    <div className="col">
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="row py-2 ft-table">
                  <div className="col">No items found</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPackageList;
