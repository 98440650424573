import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';  

const ManageWebsiteNavbar = () => {  
    return (
        <>
            <div className="bg-white mt-3 shadow p-3">
                <div className="d-flex justify-content-start">
                    <div className="me-3">
                        <Link to="/add-banner" className="text-decoration-none amz">Upload Banner</Link>
                    </div>
                    <div className="me-3">
                        <Link to="/add-category" className="text-decoration-none amz">Create Category</Link>
                    </div>
                    
                    <div className="me-3">
                        <Link to="/add-venue" className="text-decoration-none amz">Venue</Link>
                    </div>


                    <div className="me-3">
                        <Link to="/add-country" className="text-decoration-none amz">Country</Link>
                    </div>
                    <div className="me-3">
                        <Link to="/add-state" className="text-decoration-none amz">State</Link>
                    </div>
                    <div>
                        <Link to="/add-city" className="text-decoration-none amz">City</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageWebsiteNavbar;  
