import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './Component/css/common.css'; 
import './Component/css/style.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';

import Index from './Pages/Index'; 
import Login from './Pages/Login'; 
import Dashboard from './Pages/Dashboard';
import Logout from './Pages/Logout';
import AddVenue from './Pages/AddVenue';
import AddCountry from './Pages/AddCountry';
import AddState from './Pages/AddState';
import AddCity from './Pages/AddCity';
import AddBanner from './Pages/AddBanner';
import AddCategory from './Pages/AddCategory';
import AllEventsList from './Pages/AllEventsList';
import AllContactForm from './Pages/AllContactForm';
import ViewEventDetail from './Pages/ViewEventDetail';

import AllOrganiser from './Pages/AllOrganiser';
import UpdateOrganiser from './Pages/UpdateOrganiser';
import EmailPackage from './Pages/EmailPackage';
import AddTax from './Pages/AddTax';
import AddPackage from './Pages/AddPackage';
import AddPackageList from './Pages/AddPackageList';
function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} /> 
                <Route path="/dashboard" element={<Dashboard />} /> 
                <Route path="/logout" element={<Logout />} /> 
                <Route path="/add-venue" element={<AddVenue />} /> 
                <Route path="/add-country" element={<AddCountry />} /> 
                <Route path="/add-state" element={<AddState />} /> 
                <Route path="/add-city" element={<AddCity />} /> 
                <Route path="/add-banner" element={<AddBanner />} /> 
                <Route path="/add-category" element={<AddCategory />} /> 
                <Route path="/manage-events" element={<AllEventsList />} /> 
                <Route path="/contact-form-data" element={<AllContactForm />} /> 
                <Route path="/view-event-detail/:eventId" element={<ViewEventDetail />} />  

                <Route path="/manage-organiser" element={<AllOrganiser />} />  
                <Route path="/update-organiser/:orgId" element={<UpdateOrganiser />} />  
                <Route path="/manage-email-package" element={<EmailPackage />} />  
                <Route path="/add-tax" element={<AddTax />} />  
                <Route path="/add-package" element={<AddPackage />} />  
                <Route path="/add-package-list/:id" element={<AddPackageList />} />  
                </Routes>
        </Router>
    );
}

export default App;