import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../BaseURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Component/Navbar";
import AllCityList from "../Component/AllCityList";
import ManageWebsiteNavbar from "../Component/ManageWebsiteNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const AddCity = () => {
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [preview, setPreview] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  // Fetch all countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetch-all-countries`);
        if (response.data.success) {
          setCountries(response.data.countries);
        } else {
          toast.error("Failed to fetch countries");
        }
      } catch (error) {
        toast.error("An error occurred while fetching countries");
      }
    };

    fetchCountries();
  }, []);

  // Fetch all states once on component mount
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetch-all-states`);
        if (response.data.success) {
          setStates(response.data.states);
        } else {
          toast.error("Failed to fetch states");
        }
      } catch (error) {
        toast.error("An error occurred while fetching states");
      }
    };

    fetchStates();
  }, []);

  // Filter states based on selected country
  useEffect(() => {
    if (country) {
      const filtered = states.filter((state) => state.country_id === parseInt(country));
      setFilteredStates(filtered);
    } else {
      setFilteredStates([]);
    }
  }, [country, states]);

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("city_name", stateName.trim());
    formData.append("country_id", country);
    formData.append("state_id", selectedState);
    formData.append("city_image", imageFile);
  
    // Log the data being sent to the console
    console.log("Sending data:", {
      city_name: stateName.trim(),
      country_id: country,
      state_id: selectedState,
      city_image: imageFile ? imageFile.name : null, // Log the name of the file if it exists
    });
  
    try {
      const response = await axios.post(`${BASE_URL}/add-city`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Check if the response status is 201 for success
      if (response.status === 201) {
        toast.success("City added successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        // If not 201, handle the case where the server responds with a different status code
        toast.error(`Failed to add city: ${response.data.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error response:", error.response ? error.response.data : error.message);
      toast.error("An error occurred while adding the city: " + (error.response ? error.response.data.message : error.message));
    }
  };
  
  
  

  // Reset form when modal is closed
  const handleCloseModal = () => {
    setStateName("");
    setCountry("");
    setSelectedState("");
    setImageFile(null);
    setPreview("");
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <ManageWebsiteNavbar />
        <ToastContainer />
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <div className="row">
                  <div className="col">
                    <h2 className="color-red">Create City</h2>
                  </div>
                  <div className="col text-end">
                    <Link
                      className="justify-content-end amz atag d-flex align-items-center"
                      style={{ textDecoration: "none" }}
                      data-bs-toggle="modal"
                      data-bs-target="#citymodal"
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        className="amz"
                        style={{
                          cursor: "pointer",
                          color: "#fff",
                          padding: "4px 0px",
                          fontSize: "1rem",
                        }}
                      />
                      <span style={{ marginLeft: "8px" }}>Add New City</span>
                    </Link>
                  </div>
                </div>
                <div className="ptag">Create New City For Evventoz</div>

                {/* Modal Start */}
                <div
                  className="modal fade"
                  id="citymodal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  onHide={handleCloseModal} // Reset form on modal hide
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Enter a new city
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={handleCloseModal} // Reset form on modal close
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label htmlFor="countrySelect">Select Country</label>
                              <select
                                id="countrySelect"
                                className="form-control"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                <option value="">Select a country</option>
                                {countries.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.country_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="stateSelect">Select State</label>
                              <select
                                id="stateSelect"
                                className="form-control"
                                value={selectedState}
                                onChange={(e) => setSelectedState(e.target.value)}
                                disabled={!country}
                              >
                                <option value="">Select a state</option>
                                {filteredStates.map((state) => (
                                  <option key={state.state_id} value={state.state_id}>
                                    {state.state_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="state_name" className="label">
                                <span className="a-size-base">City Full Name</span>
                              </label>
                              <input
                                id="state_name"
                                className="input-class"
                                value={stateName}
                                onChange={(e) => setStateName(e.target.value)}
                                required
                                pattern="\S.+"
                                title="Please enter a valid city name."
                              />
                            </div>

                            <div className="col-md-12 mb-3">
                              <label htmlFor="image" className="label">
                                <span className="a-size-base">Image</span>
                              </label>
                              <input
                                id="image"
                                type="file"
                                className="input-class"
                                accept="image/*"
                                onChange={handleImageChange}
                                name="city_image"
                                required
                              />

                              {preview && (
                                <div className="mt-2">
                                  <img
                                    src={preview}
                                    alt="Preview"
                                    style={{ width: "100%", height: "auto" }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <button type="submit" className="bttn">
                            Add City
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal End */}

                <AllCityList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCity;
