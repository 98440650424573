import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Component/Navbar';

const Dashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check localStorage for adminId and adminUniqueId
        const adminId = localStorage.getItem('adminId');
        const adminUniqueId = localStorage.getItem('adminUniqueId');

        // If either is not available, redirect to /login
        if (!adminId || !adminUniqueId) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <>
            <Navbar />
            <div className='container'>lorem</div>
        </>
    );
};

export default Dashboard;
