import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'; // Import SweetAlert2
import 'bootstrap/dist/css/bootstrap.min.css'; 
import BASE_URL from "../BaseURL";  
import Image_URL from "../ImageURL";  
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons"; // Import the delete icon

const AllBannerList = () => {
    const [banners, setBanners] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [search, setSearch] = useState(""); 
    const [perPage, setPerPage] = useState(10); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [imageLoaded, setImageLoaded] = useState(false); 

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await fetch(`${BASE_URL}/fetch-banners`);
                const result = await response.json();

                if (result.success) {
                    setBanners(result.data); 
                } else {
                    setError("No banners found."); 
                }
            } catch (err) {
                console.error("Error fetching banners:", err);
                setError("Failed to fetch banners."); 
            } finally {
                setLoading(false); 
            }
        };

        fetchBanners(); 

        const timeout = setTimeout(() => {
            setImageLoaded(true);
        }, 1000);

        return () => clearTimeout(timeout); 
    }, []); 

    const filteredBanners = banners.filter((banner) =>
        banner.url_link.toLowerCase().includes(search.toLowerCase())
    );

    const indexOfLastBanner = currentPage * perPage;
    const indexOfFirstBanner = indexOfLastBanner - perPage;
    const currentBanners = filteredBanners.slice(indexOfFirstBanner, indexOfLastBanner);

    const handlePageChange = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const totalPages = Math.ceil(filteredBanners.length / perPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handleDeleteBanner = async (bannerId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`${BASE_URL}/delete-banner/${bannerId}`, {
                        method: 'DELETE',
                    });

                    const result = await response.json();
                    if (result.success) {
                        setBanners(banners.filter((banner) => banner.id !== bannerId));
                        Swal.fire('Deleted!', 'The banner has been deleted.', 'success');
                    } else {
                        Swal.fire('Error!', 'Failed to delete the banner.', 'error');
                    }
                } catch (err) {
                    console.error("Error deleting banner:", err);
                    Swal.fire('Error!', 'Failed to delete the banner.', 'error');
                }
            }
        });
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } 
    if (error) return <div>{error}</div>; 

    return (
        <>
            <hr />
            <div className="row mt-3 mb-3">
                <div className="col-md-10">
                    <input
                        className="input-class"
                        placeholder="Search by URL Link.."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="col-md-2 text-end">
                    <select
                        className="input-class w-100"
                        value={perPage}
                        onChange={(e) => setPerPage(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                    </select>
                </div>
            </div>

            <div className='row ft-head-table py-3 mt-2 mb-2'>
                <div className='col'>Image</div>
                <div className='col'>URL Link</div>
                <div className='col'>Created Date</div>
                <div className='col'>Action</div>
            </div>

            {currentBanners.map((banner) => (
                <div key={banner.id} className='row py-2 ft-table'>
                    <div className='col align-content-center'>
                        {!imageLoaded ? (
                            <div className="spinner-border" role="status" style={{ width: '30px', height: '30px' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <img
                                src={`${Image_URL}banner/${banner.banner_image}`} 
                                alt={banner.url_link}
                                style={{ width: '100px', display: 'block' }}
                                loading="lazy"
                            />
                        )}
                    </div>
                    <div className='col align-content-center'>{banner.url_link}</div>
                    <div className='col align-content-center'>{new Date(banner.created_at).toLocaleDateString()}</div>
                    <div className='col align-content-center'>
                        <button 
                            className="btn btn-danger" 
                            onClick={() => handleDeleteBanner(banner.id)}
                            title="Delete Banner" // Optional: Add a tooltip
                        >
                            <FontAwesomeIcon icon={faTrash} /> {/* Display the delete icon */}
                        </button>
                    </div>
                </div>
            ))}

            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className="page-item">
                            <button id={number} onClick={handlePageChange} className="page-link">
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
};

export default AllBannerList;
