import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../BaseURL";
import Navbar from "../Component/Navbar";
import ManagePackageNavbar from "../Component/ManagePackageNavbar";

const AddTax = () => {
  const [formData, setFormData] = useState({
    country: "",
    country_code: "",
    tax_name: "",
    tax_percentage: "",
  });
  const [taxRecords, setTaxRecords] = useState([]); // State to hold fetched tax records
  const [editMode, setEditMode] = useState(false); // Track if editing a record
  const [editId, setEditId] = useState(null); // Store ID of record being edited

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = editMode ? `${BASE_URL}/update-tax/${editId}` : `${BASE_URL}/add-tax`;
    const method = editMode ? "PUT" : "POST";
    try {
      const response = await fetch(endpoint, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();

      if (result.success) {
        Swal.fire("Success!", `Tax record ${editMode ? "updated" : "added"} successfully!`, "success");
        fetchAllTaxes(); // Refresh tax records
        setFormData({ country: "", country_code: "", tax_name: "", tax_percentage: "" }); // Reset form
        setEditMode(false); // Exit edit mode
      } else {
        Swal.fire("Error!", result.message, "error");
      }
    } catch (err) {
      console.error(`Error ${editMode ? "updating" : "adding"} tax record:`, err);
      Swal.fire("Error!", `Failed to ${editMode ? "update" : "add"} tax record.`, "error");
    }
  };

  const fetchAllTaxes = async () => {
    try {
      const response = await fetch(`${BASE_URL}/all-taxes`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      if (result.success) {
        setTaxRecords(result.data); // Update the taxRecords state with fetched data
      } else {
        Swal.fire("Error!", result.message, "error");
      }
    } catch (err) {
      console.error("Error fetching tax records:", err);
      Swal.fire("Error!", "Failed to fetch tax records.", "error");
    }
  };

  // Triggered when user clicks the Edit button with confirmation
  const handleEdit = (tax) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to edit this tax record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, edit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setEditMode(true);
        setEditId(tax.id); // Set the record ID for updating
        setFormData({
          country: tax.country,
          country_code: tax.country_code,
          tax_name: tax.tax_name,
          tax_percentage: tax.tax_percentage,
        });
      }
    });
  };

  // Fetch all tax records when the component mounts
  useEffect(() => {
    fetchAllTaxes();
  }, []);

  return (
    <>
      <Navbar />
      <ManagePackageNavbar />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <h2 className="color-red">{editMode ? "Edit Tax" : "Add New Tax"}</h2>
              </div>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="country" className="label">
                      <span className="a-size-base">Country</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="country_code" className="label">
                      <span className="a-size-base">Country Code</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="country_code"
                      value={formData.country_code}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="tax_name" className="label">
                      <span className="a-size-base">Tax Name</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="tax_name"
                      value={formData.tax_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="tax_percentage" className="label">
                      <span className="a-size-base">Tax Percentage</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="tax_percentage"
                      value={formData.tax_percentage}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <button type="submit" className="bttn">
                  {editMode ? "Update Tax" : "Add Tax"}
                </button>
              </form>
            </div>
          </div>

          <div className="col-md-6">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <h2 className="color-red">All Tax</h2>
              </div>
              <hr />

              <div className="row ft-head-table py-3 mt-2 mb-2">
                <div className="col">Country</div>
                <div className="col">Country Code</div>
                <div className="col">Tax Name</div>
                <div className="col">Tax Percentage</div>
                <div className="col">Actions</div>
              </div>

              {taxRecords.map((tax, index) => (
                <div className="row py-2 ft-table" key={index}>
                  <div className="col">{tax.country}</div>
                  <div className="col">{tax.country_code}</div>
                  <div className="col">{tax.tax_name}</div>
                  <div className="col">{tax.tax_percentage}%</div>
                  <div className="col">
                    <button className="btn btn-primary btn-sm me-2" onClick={() => handleEdit(tax)}>
                      Edit
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTax;
