import React, { useEffect, useState } from 'react';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import BASE_URL from "../BaseURL";  
import Image_URL from "../ImageURL";  
import Swal from 'sweetalert2'; // Import SweetAlert

const AllVenueList = () => {
    const [venues, setVenues] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [search, setSearch] = useState(""); 
    const [perPage, setPerPage] = useState(10); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [imageLoadStatus, setImageLoadStatus] = useState({}); 
    const [imageLoaded, setImageLoaded] = useState(false); // State to track when to load images

    useEffect(() => {
        const fetchVenues = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/all-venue-list`); 
                if (response.data.success) {
                    setVenues(response.data.venues); 
                } else {
                    setError("No venues found."); 
                }
            } catch (err) {
                setError("Failed to fetch venues."); 
            } finally {
                setLoading(false); 
            }
        };

        fetchVenues(); 

        // Set a timeout for 3 seconds before allowing images to load
        const timeout = setTimeout(() => {
            setImageLoaded(true);
        }, 1000);

        return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
    }, []); 

    const handleToggleChange = async (venueId, newStatus) => {
        // Show confirmation alert before proceeding
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `You are about to change the status to ${newStatus}.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isConfirmed) {
            try {
                await axios.post(`${BASE_URL}/update-venue-status`, { venue_id: venueId, status: newStatus }); 
                setVenues((prevVenues) =>
                    prevVenues.map((venue) =>
                        venue.id === venueId ? { ...venue, venue_status: newStatus } : venue 
                    )
                );
                Swal.fire(
                    'Updated!',
                    'The venue status has been updated.',
                    'success'
                );
            } catch (error) {
                console.error("Failed to update status", error);
                setError("Failed to update venue status."); 
                Swal.fire(
                    'Error!',
                    'Failed to update venue status.',
                    'error'
                );
            }
        } else {
            // Optional: Show a cancel message
            Swal.fire('Cancelled', 'No changes were made.', 'info');
        }
    };

    const filteredVenues = venues.filter((venue) =>
        venue.venue_name.toLowerCase().includes(search.toLowerCase()) ||
        venue.country_name.toLowerCase().includes(search.toLowerCase()) ||
        venue.state_name.toLowerCase().includes(search.toLowerCase()) ||
        venue.city_name.toLowerCase().includes(search.toLowerCase())
    );

    const indexOfLastVenue = currentPage * perPage;
    const indexOfFirstVenue = indexOfLastVenue - perPage;
    const currentVenues = filteredVenues.slice(indexOfFirstVenue, indexOfLastVenue);

    const handlePageChange = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const totalPages = Math.ceil(filteredVenues.length / perPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } 
    if (error) return <div>{error}</div>; 

    return (
        <>
            <hr/>
            <div className="row mt-3 mb-3">
                <div className="col-md-10">
                    <input
                        className="input-class"
                        placeholder="Search by Venue Name, Country, State, City.."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="col-md-2 text-end">
                    <select
                        className="input-class w-100"
                        value={perPage}
                        onChange={(e) => setPerPage(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                    </select>
                </div>
            </div>

            <div className='row ft-head-table py-3 mt-2 mb-2'>
                <div className='col'>Image</div>
                <div className='col'>Venue Name</div>
                <div className='col'>Country</div>
                <div className='col'>State</div>
                <div className='col'>City</div> 
                <div className='col'>Reg Date</div>
                <div className='col'>Action</div>
            </div>

            {currentVenues.map((venue) => (
                <div key={venue.id} className='row py-2 ft-table'>
                    <div className='col align-content-center'>
                        {/* Show loader while the image is loading */}
                        {!imageLoaded ? (
                            <div className="spinner-border" role="status" style={{ width: '30px', height: '30px' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <img
                                src={`${Image_URL}venue/${venue.venue_image}`}
                                alt={venue.venue_name}
                                style={{ width: '100px', display: 'block' }}
                                loading="lazy"
                                onLoad={() => setImageLoadStatus((prev) => ({ ...prev, [venue.id]: false }))} // Set loading status to false on load
                                onError={() => setImageLoadStatus((prev) => ({ ...prev, [venue.id]: false }))} // Optionally handle error case
                            />
                        )}
                    </div>
                    <div className='col align-content-center'>{venue.venue_name}</div>
                    <div className='col align-content-center'>{venue.country_name}</div>
                    <div className='col align-content-center'>{venue.state_name}</div>
                    <div className='col align-content-center'>{venue.city_name}</div> 
                    <div className='col align-content-center'>{new Date(venue.created_at).toLocaleDateString()}</div>

                    {/* Toggle button in action column */}
                    <div className='col align-content-center'>
                        <div className="form-check form-switch">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id={`flexSwitchCheckDefault${venue.id}`} 
                                checked={venue.venue_status === 'active'} 
                                onChange={(e) => handleToggleChange(venue.id, e.target.checked ? 'active' : 'deactive')} 
                            />
                            <label className="form-check-label action-label" htmlFor={`flexSwitchCheckDefault${venue.id}`}>
                                {venue.venue_status === 'active' ? 'Active' : 'Deactive'}
                            </label>
                        </div>
                    </div>
                </div>
            ))}

            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className="page-item">
                            <button id={number} onClick={handlePageChange} className="page-link">
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
};

export default AllVenueList;
