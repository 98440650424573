import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import "bootstrap/dist/css/bootstrap.min.css";
import BASE_URL from "../BaseURL";
import Image_URL from "../ImageURL";
import Navbar from "../Component/Navbar";
import ManageEventNavbar from "../Component/ManageEventNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons"; // Import the delete and view icons
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import OrgAllEvent from "../Component/OrgAllEvent";

const UpdateOrganiser = () => {
  const { orgId } = useParams(); // Get organizer ID from URL
  const [organizer, setOrganizer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    account_plan: "Basic", // Default value for dropdown
  });

  useEffect(() => {
    const fetchOrganizerDetails = async () => {
      try {
        const response = await fetch(`${BASE_URL}/fetch-organizer/${orgId}`); // Fetch organizer by ID
        const result = await response.json();

        if (result.success) {
          setOrganizer(result.organizer);
          setFormData({
            name: result.organizer.name,
            email: result.organizer.email,
            phone: result.organizer.phone,
            account_plan: result.organizer.account_plan,
          });
        } else {
          setError("Organizer not found.");
        }
      } catch (err) {
        console.error("Error fetching organizer details:", err);
        setError("Failed to fetch organizer details.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizerDetails();
  }, [orgId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/update-organizer`, {
        method: "POST", // Use POST method to update
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: orgId, ...formData }), // Include ID in the body
      });
      const result = await response.json();

      if (result.success) {
        Swal.fire("Success!", "Organizer updated successfully!", "success");
      } else {
        Swal.fire("Error!", result.message, "error");
      }
    } catch (err) {
      console.error("Error updating organizer:", err);
      Swal.fire("Error!", "Failed to update organizer.", "error");
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  if (error) return <div>{error}</div>;

  return (
    <>
      <Navbar />
      <ManageEventNavbar />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="bg-white mt-3 shadow p-3">
              <OrgAllEvent />
            </div>
          </div>

          <div className="col-md-4">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <h2 className="color-red">Update Organizer</h2>
              </div>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name" className="label">
                      <span className="a-size-base">Name</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="email" className="label">
                      <span className="a-size-base">Email</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="phone" className="label">
                      <span className="a-size-base">Phone</span>
                    </label>
                    <input
                      className="input-class"
                      required
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="account_plan" className="label">
                      <span className="a-size-base">Plan</span>
                    </label>
                    <select
                      className="input-class"
                      name="account_plan"
                      value={formData.account_plan}
                      onChange={handleInputChange}
                    >
                      <option value="Basic">Basic</option>
                      <option value="Gold">Gold</option>
                      <option value="Advance">Advance</option>
                      <option value="Premium">Premium</option>
                    </select>
                  </div>
                </div>
                <button type="submit" className="bttn">
                  Update Organizer
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateOrganiser;
