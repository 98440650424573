import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../BaseURL"; // Ensure this file contains your base URL
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Component/Navbar";
import AllPackageList from "../Component/AllPackageList";
import ManagePackageNavbar from "../Component/ManagePackageNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const AddPackage = () => {
  // State variables for form handling
  const [packageName, setPackageName] = useState("");
  const [packagePriceINR, setPackagePriceINR] = useState("");
  const [packagePriceUSD, setPackagePriceUSD] = useState("");
  const [quarterlyINR, setQuarterlyINR] = useState("");
  const [quarterlyUSD, setQuarterlyUSD] = useState("");
  const [halfYearINR, setHalfYearINR] = useState("");
  const [halfYearUSD, setHalfYearUSD] = useState("");
  const [yearINR, setYearINR] = useState("");
  const [yearUSD, setYearUSD] = useState("");

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!packageName || !packagePriceINR || !packagePriceUSD) {
      toast.error("All fields are required.");
      return;
    }

    // Create the package data object
    const packageData = {
      name: packageName,
      price_inr: packagePriceINR,
      price_usd: packagePriceUSD,
      quarterly_inr: quarterlyINR,
      quarterly_usd: quarterlyUSD,
      half_year_inr: halfYearINR,
      half_year_usd: halfYearUSD,
      year_inr: yearINR,
      year_usd: yearUSD,
    };

    try {
      const response = await axios.post(`${BASE_URL}/add-package`, packageData);
      toast.success("Package added successfully!");

      // Reset the form fields
      setPackageName("");
      setPackagePriceINR("");
      setPackagePriceUSD("");
      setQuarterlyINR("");
      setQuarterlyUSD("");
      setHalfYearINR("");
      setHalfYearUSD("");
      setYearINR("");
      setYearUSD("");

      // Close the modal programmatically
      const modalElement = document.getElementById("packageModal");
      const modal = new window.bootstrap.Modal(modalElement);
      modal.hide();

      // Reload the page after adding the package
      window.location.reload();
    } catch (error) {
      console.error("Error adding package:", error);
      toast.error(
        "Failed to add package. " + (error.response?.data?.message || "")
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <ManagePackageNavbar />
        <ToastContainer />
        <div className="row justify-content-start">
          <div className="col-md-12">
            <div className="bg-white mt-3 shadow p-3">
              <div className="text-start">
                <div className="row">
                  <div className="col">
                    <h2 className="color-red">Package</h2>
                  </div>
                  <div className="col text-end">
                    <Link
                      className="justify-content-end amz atag d-flex align-items-center"
                      style={{ textDecoration: "none" }}
                      data-bs-toggle="modal"
                      data-bs-target="#packageModal"
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        className="amz"
                        style={{
                          cursor: "pointer",
                          color: "#fff",
                          padding: "4px 0px",
                          fontSize: "1rem",
                        }}
                      />
                      <span style={{ marginLeft: "8px" }}>Add New Package</span>
                    </Link>
                  </div>
                </div>
                <div className="ptag">
                  Create New Package For Evventoz Platform
                </div>

                {/* Modal Start */}
                <div
                  className="modal fade"
                  id="packageModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add New Package
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label htmlFor="packageName" className="label">
                                <span className="a-size-base">Package Name</span>
                              </label>
                              <input
                                className="input-class"
                                id="packageName"
                                value={packageName}
                                onChange={(e) => setPackageName(e.target.value)}
                                required
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label htmlFor="packagePriceINR" className="label">
                                <span className="a-size-base">Package Price (INR)</span>
                              </label>
                              <input
                                id="packagePriceINR"
                                type="number"
                                className="input-class"
                                value={packagePriceINR}
                                onChange={(e) => setPackagePriceINR(e.target.value)}
                                required
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label htmlFor="packagePriceUSD" className="label">
                                <span className="a-size-base">Package Price (USD)</span>
                              </label>
                              <input
                                id="packagePriceUSD"
                                type="number"
                                className="input-class"
                                value={packagePriceUSD}
                                onChange={(e) => setPackagePriceUSD(e.target.value)}
                                required
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label htmlFor="quarterlyINR" className="label">
                                <span className="a-size-base">Quarterly Price (INR)</span>
                              </label>
                              <input
                                id="quarterlyINR"
                                type="text"
                                className="input-class"
                                value={quarterlyINR}
                                onChange={(e) => setQuarterlyINR(e.target.value)}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label htmlFor="quarterlyUSD" className="label">
                                <span className="a-size-base">Quarterly Price (USD)</span>
                              </label>
                              <input
                                id="quarterlyUSD"
                                type="text"
                                className="input-class"
                                value={quarterlyUSD}
                                onChange={(e) => setQuarterlyUSD(e.target.value)}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label htmlFor="halfYearINR" className="label">
                                <span className="a-size-base">Half Year Price (INR)</span>
                              </label>
                              <input
                                id="halfYearINR"
                                type="text"
                                className="input-class"
                                value={halfYearINR}
                                onChange={(e) => setHalfYearINR(e.target.value)}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label htmlFor="halfYearUSD" className="label">
                                <span className="a-size-base">Half Year Price (USD)</span>
                              </label>
                              <input
                                id="halfYearUSD"
                                type="text"
                                className="input-class"
                                value={halfYearUSD}
                                onChange={(e) => setHalfYearUSD(e.target.value)}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label htmlFor="yearINR" className="label">
                                <span className="a-size-base">Year Price (INR)</span>
                              </label>
                              <input
                                id="yearINR"
                                type="text"
                                className="input-class"
                                value={yearINR}
                                onChange={(e) => setYearINR(e.target.value)}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label htmlFor="yearUSD" className="label">
                                <span className="a-size-base">Year Price (USD)</span>
                              </label>
                              <input
                                id="yearUSD"
                                type="text"
                                className="input-class"
                                value={yearUSD}
                                onChange={(e) => setYearUSD(e.target.value)}
                              />
                            </div>

                            <div className="col-12 mb-3 text-center">
                              <button type="submit" className="btn btn-primary">
                                Add Package
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal End */}
              </div>
              <AllPackageList />
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default AddPackage;
